
<div *ngIf="isModalTicketLogs; else elseBlock">
    <ng-select #selectPadrao
        style="border-radius: 50px;"
        *ngIf="entidade !== 'selectPais' && entidade !== 'selectClientes' && entidade !== 'selectIdioma' && entidade !== 'selectDistro' && entidade !== 'selectDispositivoVinculado' && entidade !== 'selectCodigoDdiPais' && entidade !== 'selectVisibilidadeFiltros' && entidade !== 'selectBusinessUnitCustom' && entidade !== 'selectClienteEndereco' && entidade !== 'selectEnderecoId' && entidade !== 'selectBooleano' && entidade !== 'selectArch' && entidade !== 'selectTipoSolicitante'"
        [(ngModel)]="ctrl.selectedValue" 
        (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" 
        [disabled]="disabled"
        [required]="required" 
        [multiple]="multiple" 
        placeholder="{{(customPlaceholder) ? (customPlaceholder | translate) : ('SELECIONE' | translate)}}" 
        class="custom-modal-ticket-logs ng-invalid" 
        [closeOnSelect]="(multiple === true) ? false : true"
        (click)="verifyLoadResources()"
        [ngClass]="{'select-required': required}" 
        (search)="onSearchTermChange($event)"
        (clear)="cleared = true; filtrarPersonalizado({term: ''})" 
        [loading]="loading" 
        (scrollToEnd)="scrollToEnd(ctrl.itens)" 
        [dropdownPosition]="openReference">
        <ng-option #option *ngFor="let item of ctrl.itens" [value]="(entidade === 'selectDataDinamicaCondicaoFiltro') ? item.chave : item.id">
            <span *ngIf="entidade !== 'selectEquipes'" [title]="item.text || item.label || item.nome" [ngbTooltip]="item.text || item.label || item.nome">{{item.text || item.label || item.nome}}</span>
            <span *ngIf="entidade === 'selectEquipes'" [title]="item.membros" [ngbTooltip]="item.membros">{{item.nome}} ({{item.membros}})</span>
            <span *ngIf="entidade === 'selectProdutosPecas'" [title]="item.texto" [ngbTooltip]="item.texto">{{item.texto}}</span>
            <span *ngIf="entidade === 'selectTicketsConciliados'" [title]="'#' + item.codigo + ' - ' + item.assunto" [ngbTooltip]="'#' + item.codigo + ' - ' + item.assunto">#{{item.codigo}} - {{item.assunto}}</span>
            <span *ngIf="entidade === 'selectChamadosConciliadosSimplified'" [title]="'#' + item.codigo + ' - ' + item.assunto" [ngbTooltip]="'#' + item.codigo + ' - ' + item.assunto">#{{item.codigo}} - {{item.assunto}}</span>
            <div class="row" *ngIf="entidade === 'selectWhiteLabelOnline'">
                <div class="col-6">
                    <img [src]="item.id" width="32px" height="32px">
                </div>
            </div>
            <div class="row" *ngIf="entidade === 'selectWhiteLabelOffline'">
                <div class="col-6">
                    <img [src]="item.id" width="32px" height="32px">
                </div>
            </div>
        </ng-option>
        <ng-template *ngIf="entidade === 'selectDropdownGrupos'" ng-footer-tmp>
            <p class="create-new text-center" (click)="adicionarNovo('grupo')"><i class="fa fa-plus"></i> {{'ADICIONAR_NOVO'
                | translate}}</p>
        </ng-template>
    </ng-select>
</div>
<ng-template #elseBlock>
    <ng-select container=".modal-content" #selectPadrao
    *ngIf="entidade !== 'selectPais' && entidade !== 'selectClientes' && entidade !== 'selectIdioma' && entidade !== 'selectDistro' && entidade !== 'selectDispositivoVinculado' && entidade !== 'selectCodigoDdiPais' && entidade !== 'selectVisibilidadeFiltros' && entidade !== 'selectBusinessUnitCustom' && entidade !== 'selectClienteEndereco' && entidade !== 'selectEnderecoId' && entidade !== 'selectBooleano' && entidade !== 'selectArch' && entidade !== 'selectTipoSolicitante'"
    [(ngModel)]="ctrl.selectedValue"
    (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" 
    [disabled]="disabled"
    [required]="required" 
    [multiple]="multiple"
    placeholder="{{(customPlaceholder) ? (customPlaceholder | translate) : ('SELECIONE' | translate)}}" 
    class="custom ng-invalid teste" [closeOnSelect]="(multiple === true) ? false : true"
    (click)="verifyLoadResources()" 
    [ngClass]="{'select-required': required}" (search)="filtrarPersonalizado($event)"
    (clear)="cleared = true; emitValue(null); filtrarPersonalizado({term: ''})" 
    [loading]="loading" 
    (scrollToEnd)="scrollToEnd(ctrl.itens)" 
    [dropdownPosition]="openReference">
        <ng-option container=".modal-content" *ngFor="let item of ctrl.itens" [value]="(entidade === 'selectDataDinamicaCondicaoFiltro') ? item.chave : item.id">
            <span *ngIf="entidade !== 'selectEquipes'" [title]="item.text || item.label || item.nome || item.descricao" [ngbTooltip]="item.text || item.label || item.nome || item.descricao">{{item.text || item.label || item.nome || item.descricao}}</span>
            <span *ngIf="entidade === 'selectEquipes'" [title]="item.membros" [ngbTooltip]="item.membros">{{item.nome}} ({{item.membros}})</span>
            <span *ngIf="entidade === 'selectProdutosPecas'" [title]="item.texto" [ngbTooltip]="item.texto">{{item.texto}}</span>
            <span *ngIf="entidade === 'selectTicketsConciliados'" [title]="'#' + item.codigo + ' - ' + item.assunto" [ngbTooltip]="'#' + item.codigo + ' - ' + item.assunto">#{{item.codigo}} - {{item.assunto}}</span>
            <span *ngIf="entidade === 'selectChamadosConciliadosSimplified'" [title]="'#' + item.codigo + ' - ' + item.assunto" [ngbTooltip]="'#' + item.codigo + ' - ' + item.assunto">#{{item.codigo}} - {{item.assunto}}</span>
            <div class="row" *ngIf="entidade === 'selectWhiteLabelOnline'">
                <div class="col-6">
                    <img [src]="item.id" width="32px" height="32px">
                </div>
            </div>
            <div class="row" *ngIf="entidade === 'selectWhiteLabelOffline'">
                <div class="col-6">
                    <img [src]="item.id" width="32px" height="32px">
                </div>
            </div>
        </ng-option>
        <ng-template *ngIf="entidade === 'selectDropdownGrupos'" ng-footer-tmp>
            <p class="create-new text-center" (click)="adicionarNovo('grupo')"><i class="fa fa-plus"></i> {{'ADICIONAR_NOVO'
                | translate}}</p>
        </ng-template>
    </ng-select>

    <ng-select #selectPadrao [id]="selectId" class="custom ng-invalid" *ngIf="entidade === 'selectIdioma'" [(ngModel)]="ctrl.selectedValue"
        [disabled]="disabled" (change)="emitValue(ctrl.selectedValue)" placeholder="{{'SELECIONE' | translate}}"
        [ngClass]="{'select-required': required === true}" [required]="required" [searchable]="false" [clearable]="false"
        (click)="verifyLoadResources()">
        <ng-option [value]="1">
            <img src="assets/img/icons/flags/BR.png" width="20px" height="20px">
        </ng-option>
        <ng-option [value]="2">
            <img src="assets/img/icons/flags/ES.png" width="20px" height="20px">
        </ng-option>
        <ng-option [value]="3">
            <img src="assets/img/icons/flags/US.png" width="20px" height="20px">
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao class="custom ng-invalid" *ngIf="entidade === 'selectDistro'" [(ngModel)]="ctrl.selectedValue"
        [disabled]="disabled" (change)="emitValue(ctrl.selectedValue)" placeholder="{{'SELECIONE' | translate}}"
        [ngClass]="{'select-required': required === true}" [required]="required" [searchable]="false" [clearable]="false"
        (click)="verifyLoadResources()">
        <ng-option [value]="'suse'">
            <span><i class="fab fa-suse"></i> &nbsp; OpenSuse (15.6, 15.5) - Arm64, x64</span>
        </ng-option>
        <ng-option [value]="'ubuntu'">
            <span><i class="fab fa-ubuntu"></i> &nbsp; Ubuntu (24.04, 23.10, 22.04, 20.04) - Arm64, x64</span>
        </ng-option>
        <ng-option [value]="'debian'">
            <span><i class="fab fa-linux"></i> &nbsp; Debian (12, 11) - Arm64, x64</span>
        </ng-option>
        <ng-option [value]="'mint'">
            <span><i class="fab fa-linux"></i> &nbsp; Linux Mint 21.03 - Arm64, x64</span>
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao class="custom ng-invalid" *ngIf="entidade === 'selectDispositivoVinculado'" [(ngModel)]="ctrl.selectedValue"
        (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" [disabled]="disabled" [required]="required"
        [multiple]="multiple" placeholder="{{'SELECIONE' | translate}}"
        [ngClass]="{'select-required': required === true}" (search)="onSearchTermChange($event)"
        (click)="verifyLoadResources()"
        (clear)="cleared = true; filtrarPersonalizado({term: ''})">
        <ng-option *ngFor="let item of ctrl.itens" [value]="item.id">
            <div class="row col" *ngIf="hasOwnProperty(item, is_home_office)">
                <span *ngIf="item.is_home_office === true">
                    <img  style="color: #2DCE89;" src="assets/img/icons/svg/homeoffice.svg" alt="home_office" width="20px" height="20px"> &nbsp; {{item.text}}
                </span>
                <span *ngIf="item.is_home_office === false"> {{item.text}}</span>            
            </div>
            <div *ngIf="!hasOwnProperty(item, is_home_office)">
                <span [title]="item.text">{{item.text}}</span>
            </div>
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao
        *ngIf="entidade === 'selectCodigoDdiPais'"
        [(ngModel)]="ctrl.selectedValue" (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" [disabled]="disabled"
        [required]="required" [multiple]="multiple" placeholder="{{'SELECIONE' | translate}}" class="custom ng-invalid"
        [ngClass]="{'select-required': required === true}" (search)="onSearchTermChange($event)"
        (click)="verifyLoadResources()"
        (clear)="cleared = true; filtrarPersonalizado({term: ''})" [loading]="loading" (scrollToEnd)="scrollToEnd(ctrl.itens)">
        <ng-option *ngFor="let item of ctrl.itens" [value]="item.id"> 
            <span>+{{item.valor}} {{item.text}}</span>
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao class="custom ng-invalid" *ngIf="entidade === 'selectVisibilidadeFiltros'" [(ngModel)]="ctrl.selectedValue" (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" [disabled]="disabled" [required]="required" placeholder="{{'SELECIONE' | translate}}" [ngClass]="{'select-required': required === true}" (search)="onSearchTermChange($event)" (clear)="cleared = true; onSearchTermChange({term: ''})"
    (click)="verifyLoadResources()">
        <ng-option *ngFor="let item of ctrl.itens" [value]="item.id">
            <i class="mdi font-21 mr-2" [ngClass]="{'mdi-lock-outline': item.id === 1, 'mdi-lock-open-variant-outline': item.id === 2}"></i>
            {{item.text}}
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao
        *ngIf="entidade === 'selectPais'"
        [(ngModel)]="ctrl.selectedValue" [model]="ctrl.selectedValue" [items]="ctrl.itens" bindValue="id" bindLabel="text" (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" [disabled]="disabled"
        [required]="required" [multiple]="multiple" placeholder="{{'SELECIONE' | translate}}" class="custom ng-invalid"
        [ngClass]="{'select-required': required === true}" (search)="onSearchTermChange($event)"
        (clear)="cleared = true; onSearchTermChange({term: ''})" [loading]="loading" (scrollToEnd)="scrollToEnd(ctrl.itens)"
        (click)="verifyLoadResources()">
    </ng-select>

    <ng-select #selectPadrao
        *ngIf="entidade === 'selectBusinessUnitCustom' || entidade === 'selectClienteEndereco' || entidade === 'selectEnderecoId'"
        [(ngModel)]="ctrl.selectedValue" [model]="ctrl.selectedValue" (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" [disabled]="disabled"
        [required]="required" [multiple]="multiple" placeholder="{{(customPlaceholder) ? (customPlaceholder | translate) : ('SELECIONE' | translate)}}" class="custom ng-invalid" [closeOnSelect]="(multiple === true) ? false : true"
        [ngClass]="{'select-required': required}" (search)="onSearchTermChange($event)"
        (clear)="cleared = true; onSearchTermChange({term: ''})"
        (click)="verifyLoadResources()">
        <ng-option *ngFor="let item of ctrl.itens" [value]="item.id">
            <ng-container *ngIf="item.descricao || item.text; else noDescriptionBU">
                <span [title]="item.descricao || item.text">{{item.descricao || item.text}}</span>
            </ng-container>
            <ng-template #noDescriptionBU>
                <span style="font-size: 12px;" [title]="(item.logradouro || item.tipologradouro) + ', ' + item.numero" container="body" [ngbTooltip]="(item.logradouro || item.tipologradouro) + ', ' + item.numero">{{(item.logradouro || item.tipologradouro) + ', ' + item.numero}}</span>
                <br>
                <span style="font-size: 9px;">{{item.bairro + ', ' + item.cidade + ' - ' + item.estado}}</span>
            </ng-template>
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao
        *ngIf="entidade === 'selectClientes'"
        [(ngModel)]="ctrl.selectedValue" (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" [disabled]="disabled"
        [required]="required" [multiple]="multiple" placeholder="{{(customPlaceholder) ? (customPlaceholder | translate) : ('SELECIONE' | translate)}}" class="custom ng-invalid" [closeOnSelect]="(multiple === true) ? false : true"
        [ngClass]="{'select-required': required}" (search)="onSearchTermChange($event)"
        (clear)="cleared = true; onSearchTermChange({term: ''})"
        [loading]="loading" (scrollToEnd)="scrollToEnd(ctrl.itens)"
        (click)="verifyLoadResources()" 
        [dropdownPosition]="openReference">
        <ng-option *ngFor="let item of ctrl.itens;" [value]="item.id">
            <div>
                <span [title]="item.text || item.label || item.nome" container="body" [ngbTooltip]="item.text || item.label || item.nome">{{item.text || item.label || item.nome}}</span>
            </div>
            <span style="font-size: 10px;">{{item.endereco}}</span>
            <span style="display: none;">{{item.documento}}</span>
        </ng-option>
        <ng-template ng-footer-tmp>
            <p class="create-new text-center" (click)="redirecionaTelaClientes()"><i class="fa fa-plus"></i> {{'ADICIONAR_NOVO'
                | translate}}</p>
        </ng-template>
    </ng-select>

    <ng-select #selectPadrao
        *ngIf="entidade === 'selectBooleano'"
        [(ngModel)]="ctrl.selectedValue" (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" [disabled]="disabled"
        [required]="required" placeholder="{{(customPlaceholder) ? (customPlaceholder | translate) : ('SELECIONE' | translate)}}"
        [ngClass]="{'select-required': required}"
        (clear)="cleared = true"
        [loading]="loading" (scrollToEnd)="scrollToEnd(ctrl.itens)"
        class="custom ng-invalid" [clearable]="false"
        (click)="verifyLoadResources()">
        <ng-option *ngFor="let item of ctrl.itens;" [value]="item.value">
            <div>
                <span [title]="item.text" container="body" [ngbTooltip]="item.text">{{item.text}}</span>
            </div>
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao class="custom ng-invalid" *ngIf="entidade === 'selectArch'" [(ngModel)]="ctrl.selectedValue"
        [disabled]="disabled" (change)="emitValue(ctrl.selectedValue)" placeholder="{{'SELECIONE' | translate}}"
        [ngClass]="{'select-required': required === true}" [required]="required" [searchable]="false" [clearable]="false"
        (click)="verifyLoadResources()">
        <ng-option [value]="'x64'">
            <span><i class="fab fa-windows"></i> &nbsp; Windows - x64</span>
        </ng-option>
        <ng-option [value]="'x86'">
            <span><i class="fab fa-windows"></i> &nbsp; Windows - x86</span>
        </ng-option>
    </ng-select>

    <ng-select #selectPadrao
        style="border-radius: 50px;"
        *ngIf="entidade === 'selectTipoSolicitante'"
        [(ngModel)]="ctrl.selectedValue" 
        (change)="emitValue(ctrl.selectedValue); selectPadrao.blur()" 
        [disabled]="disabled"
        [required]="required" 
        [multiple]="multiple" 
        placeholder="{{('SELECT_SOLICITANTE_PLACEHOLDER' | translate)}}" 
        class="custom ng-invalid" 
        [closeOnSelect]="(multiple === true) ? false : true"
        (click)="verifyLoadResources()"
        [ngClass]="{'select-required': required}" 
        (search)="onSearchTermChange($event)"
        (clear)="cleared = true; onSearchTermChange({term: ''})" 
        [loading]="loading" 
        (scrollToEnd)="scrollToEnd(ctrl.itens)" 
        [dropdownPosition]="openReference">
        <ng-option *ngFor="let item of ctrl.itens;" [value]="item.id">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <i *ngIf="item.tipo_solicitante_id === 1" class="fa-regular fa-apartment"></i>
                    <i *ngIf="item.tipo_solicitante_id === 3" class="fa-regular fa-user-headset"></i>
                    <i *ngIf="item.tipo_solicitante_id === 2" class="far fa-user"></i>
                </div>
                <div class="d-grid">
                    <span [title]="item.text">{{item.text}}</span>
                    <span style="font-size: 10px;">
                        {{verificaComplementoSelectSolicitante(item)}}
                    </span>
                </div>
            </div>
        </ng-option>
        <ng-template ng-footer-tmp>
            <div class="d-flex justify-content-around">
                <p class="create-new text-center" (click)="redirecionaTelaClientes()">
                    <i class="fa-regular fa-apartment"></i> {{'ADICIONAR_NOVO_CLIENTE' | translate}}
                </p>
                <p class="create-new text-center" (click)="openModalAdicionaContato()">
                    <i class="fas fa-user"></i> {{'ADICIONAR_NOVO_CONTATO' | translate}}
                </p>
            </div>
        </ng-template>
    </ng-select>

</ng-template>