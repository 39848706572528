import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class DadosEmpresaService {

  private apiUrl = environment.apiGestorUrlLocal;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  obterExpediente(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/expediente/empresa/`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterDados(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateDados(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/empresas`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateDadosFaturamento(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/empresas/dados-faturamento`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateExpediente(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/expediente/empresa`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadLogo(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/empresas/atualiza/logo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteEmpresLogo(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/empresas/logo/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteEmpresLogoRelatorio(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/empresas/logo-relatorio/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadRelatorio(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/empresas/atualiza/logo/relatorio`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  obterDadosFaturamento(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/dados-faturamento`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
