import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  atualizaAlertaPorCliente(cliente_id, alerta): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/configuracao-alerta/cliente/${cliente_id}`, alerta, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  getContadorAlertas(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/Alerta/getCount`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemAlertas(params, filters?): Observable<any> {
    this.httpOptions.params = params;
    if(filters) {
      this.httpOptions.params = {...params, ...filters};
    }
    return this.http.get(`${this.apiUrl}api/Alerta/listNotificacaoTodos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAlertasByEmpresa(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracao-alerta/empresa`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaAlertaPorEmpresa(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/configuracao-alerta/empresa`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
  }

  getConfigUserAlerts(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/alertas/tipo/configUser/${id}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateConfigUserAlerts(id, payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/alertas/tipo/configUser/${id}`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  marcarAlertaComoLido(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/Alerta/marcar-lido`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  //------- AUTOMAÇÃO DE ALERTAS -------//
  getDropdownTiposAlertas(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-alertas/dropdown-tipo-gatilho-alerta`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownOperadoresAlertas(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-alertas/dropdown-operadores`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownCondicoesAlertas(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-alertas/dropdown-condicoes`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  getInformacoesCampoAlertas(chave): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-alertas/obter-informacoes/${chave}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemAlertasAutomaticos(filtros, params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(
      `${this.apiUrl}api/gatilho-alertas/listagem`, 
      filtros, 
      this.httpOptions
    )
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirGatilhoAlertas(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/gatilho-alertas/${id}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  playGatilhoAlertas(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/gatilho-alertas/iniciar/${id}`, '', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  pauseGatilhoAlertas(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/gatilho-alertas/pausar/${id}`, '', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  criarNovoGatilhoAlertas(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/gatilho-alertas`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  editarNovoGatilhoAlertas(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/gatilho-alertas`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  obterGatilhoAlerta(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/gatilho-alertas/${id}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
