import { Usuario } from 'src/app/models/usuario.model';
import { TokenService } from '../core/token/token.service';
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  iconsvg?: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
  permission?: string;
  is_new?: boolean;
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
  permission?: string;
  is_new?: boolean;
}

export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
  permission?: string;
  visible?: boolean;
  is_new?: boolean;
}

export class SidebarRoutes {
  userChat: RouteInfo[] = [
    {
      path: "/dashboards",
      title: "Dashboards",
      type: "sub",
      icontype: "mdi mdi-view-dashboard-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "",
          title: "DASH_CANAIS_ATENDIMENTO",
          type: "group",
          children: [
            {
              path: "service-channels",
              title: "GERAL",
              type: "link"
            },
            {
              path: "users-status",
              title: "DASH_STATUS_DO_OPERADOR",
              type: "link"
            }
          ],
        },
        {
          path: "",
          title: "CHAT",
          type: "group",
          children: [
            {
              path: "chat",
              title: "GERAL",
              type: "link",
              permission: "@VisualizarDashboardChat",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "tv-mode-groups",
              title: "GRUPOS_MODO_TV",
              type: "link",
              permission: "@VisualizarModoTv",
            },
          ],
        },
      ],
    },
    {
      path: "/chat",
      title: "CHAT",
      type: "sub",
      icontype: "mdi mdi-chat-outline icon-sidenav",
      isCollapsed: true,
      is_new: true,
      children: [
        {
          path: "chat-history",
          title: "HISTORICO",
          type: "link",
          permission: "@VisualizarHistoricoChat",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            { path: "service-queue", title: "FILA_ATENDIMENTO", type: "link", permission: "@VisualizarWhatsapp" }
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            { path: "configuration-list", title: "Chat", type: "link", permission: "@VisualizarWhatsapp" },
            {
              path: "whatsapp-list",
              title: "WhatsApp",
              type: "link",
              permission: "@VisualizarWhatsapp",
            },
            {
              path: "telegram-list",
              title: "Telegram",
              type: "link",
              permission: "@VisualizarTelegram",
            },
            {
              path: "workflow-chat",
              title: "Workflow",
              type: "link",
              permission: '@PossuiWorkflows',
              is_new: true
            }
          ],
        },
      ],
    },
    {
      path: "/library",
      title: "BIBLIOTECA",
      type: "sub",
      icontype: "mdi mdi-book-open-page-variant-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "knowledge-base",
          title: "BASE_CONHECIMENTO",
          type: "link",
          permission: "@VisualizarBaseConhecimento",
        }
      ],
    },
    {
      path: "/registration",
      title: "CADASTROS",
      type: "sub",
      icontype: "mdi mdi-folder-edit-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "profiles-list",
          title: "PERFIS_OPERADORES",
          type: "link",
          permission: "@VisualizaPerfilUsuario",
        },
        {
          path: "operator-list",
          title: "OPERADORES",
          type: "link",
          permission: "@VisualizaUsuario",
        },
        {
          path: "customers-list",
          title: "CLIENTES",
          type: "link",
          permission: "@VisualizaCliente",
        },
        {
          path: "holiday-list",
          title: "FERIADOS",
          type: "link",
          permission: "@VisualizarFeriados",
        },
        {
          path: "office-hour",
          title: "EXPEDIENTES",
          type: "link",
          permission: "@VisualizarExpediente"
        },
        {
          path: "",
          title: "DOCUMENTOS",
          type: "group",
          children: [
            {
              path: "graphic-art-list",
              title: "ARTE_GRAFICA",
              type: "link",
              permission: "@VisualizarDocumentoArteGrafica",
            },
            {
              path: "contract-list",
              title: "CONTRATOS",
              type: "link",
              permission: "@VisualizarDocumentoContratos",
            },
            {
              path: "miscellaneous-documents-list",
              title: "DOCUMENTOS_DIVERSOS",
              type: "link",
              permission: "@VisualizarDocumentoDiversos",
            },
          ],
        },
      ],
    }
  ];
  userTicket: RouteInfo[] = [
    {
      path: "/dashboards",
      title: "Dashboards",
      type: "sub",
      icontype: "mdi mdi-view-dashboard-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "",
          title: "DASH_CANAIS_ATENDIMENTO",
          type: "group",
          children: [
            {
              path: "service-channels",
              title: "GERAL",
              type: "link"
            },
            {
              path: "users-status",
              title: "DASH_STATUS_DO_OPERADOR",
              type: "link"
            }
          ],
        },
        {
          path: "",
          title: "CHAT",
          type: "group",
          children: [
            {
              path: "chat",
              title: "GERAL",
              type: "link",
              permission: "@VisualizarDashboardChat",
            },
          ],
        },
        {
          path: "",
          title: "Tickets",
          type: "group",
          children: [
            {
              path: "dashboard",
              title: "GERAL",
              type: "link",
              permission: "@VisualizarDashboard",
            },
            {
              path: "main",
              title: "PRINCIPAL",
              type: "link",
              permission: "@VisualizarDashboardPrincipal",
            },
            {
              path: "sla",
              title: "SLA",
              type: "link",
              permission: "@VisualizarDashBoardSLA",
            },
            {
              path: "tables",
              title: "MESAS_TRABALHO",
              type: "link",
              permission: "@VisualizarMesas",
            },
            {
              path: "ticket",
              title: "FLUXO_ATENDIMENTO",
              type: "link",
              permission: "@VisualizarTicket",
            },
            {
              path: "technician",
              title: "OPERADORES",
              type: "link",
              permission: "@VisualizarTecnico",
            },
            {
              path: "tasks",
              title: "TAREFAS",
              type: "link",
              permission: "@VisualizarTarefas",
            },
            {
              path: "ticket-classification",
              title: "AVALIACAO_EXTERNA",
              type: "link",
              permission: "@VisualizarClassificacaoCliente",
            },
            {
              path: "internal-classification",
              title: "AVALIACAO_INTERNA",
              type: "link",
              permission: "@VisualizarDashboardClassificacaoInterna",
            },
            {
              path: "approval",
              title: "APROVACAO",
              type: "link",
              permission: "@VisualizarDashboardClassificacaoInterna",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "tv-mode-groups",
              title: "GRUPOS_MODO_TV",
              type: "link",
              permission: "@VisualizarModoTv",
            },
          ],
        },
      ],
    },
    {
      path: "/tickets",
      title: "TICKETS",
      type: "sub",
      icontype: "mdi mdi-ticket-confirmation-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "list",
          title: "LISTAGEM_DE_TICKETS",
          type: "link",
          permission: "@VisualizarTickets",
        },
        {
          path: "tickets-withheld",
          title: "LISTAGEM_TICKETS_RETIDOS",
          type: "link",
          permission: "@VisualizarChamadoPorEmail",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            {
              path: "checklist",
              title: "CHECKLIST",
              type: "link",
              permission: "@VisualizarChecklist",
            },
            {
              path: "template-configuration",
              title: "TEMPLATES",
              type: "link",
              permission: "@VisualizarTemplateComentarios",
            },
            {
              path: "ticket-opening-form",
              title: "FORMULARIO_DE_ABERTURA",
              type: "link",
              permission: "@VisualizarFormularioAberturaTicket",
            },
            {
              path: "pause-reason",
              title: "MENU_MOTIVO_PAUSA",
              type: "link",
              permission: "@VisualizarMotivoPausa",
            },
            {
              path: "mail-box-configuration",
              title: "CAIXA_EMAIL",
              type: "link",
              permission: "@VisualizarChamadoPorEmail",
            },
            {
              path: "ticket-type-list",
              title: "TIPOS_TICKETS",
              type: "link",
              permission: "@VisualizarTipoChamado",
            },
            // {
            //   path: "category-list",
            //   title: "CATEGORIAS",
            //   type: "link",
            //   permission: "@VisualizaCategoriaPrimaria",
            // },
            {
              path: "category-catalogue-list",
              title: "CATALOGO_CATEGORIAS",
              type: "link",
              permission: "@VisualizarCatalogoCategorias"
            },
            {
              path: "category-groups-list",
              title: "GRUPOS_CATEGORIA",
              type: "link",
              permission: "@VisualizarGrupoCategorias"
            },
            {
              path: "work-desks-list",
              title: "MESAS_TRABALHO",
              type: "link",
              permission: "@VisualizarMesaTrabalho",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "workflow",
              title: "AUTOMACAO",
              type: "link",
              permission: "@VisualizarAutomacaoMonitoramento",
            },
            {
              path: "sla-settings",
              title: "SLA",
              type: "link",
              permission: "@VisualizarConfiguracaoSLA",
            },
            {
              path: "tickets-fields-configuration",
              title: "CAMPOS_CUSTOMIZAVEIS",
              type: "link",
              permission: "@VisualizarConfiguracoesCampos",
            },
            {
              path: "rules-fields",
              title: "REGRA_CAMPOS",
              type: "link",
              permission: "@VisualizarRegrasCampos",
            },
            {
              path: "satisfaction-survey",
              title: "PESQUISA_SATISFACAO",
              type: "link",
              permission: "@VisualizarPesquisaSatisfacao",
            },
            {
              path: "opening",
              title: "ABERTURA_TICKET",
              type: "link",
              permission: "@VisualizarAberturaChamado",
            },
            {
              path: "closing",
              title: "FINALIZACAO_TICKET",
              type: "link",
              //permission: "@VisualizarAberturaChamado",
            },
            {
              path: "setting-emails",
              title: "DISPARO_EMAIL",
              type: "link",
              permission: "@VisualizarConfigDisparoEmail",
            },
            {
              path: "approval",
              title: "APROVACAO",
              type: "link",
              permission: "@EditarAprovacaoTicket",
            },
          ],
        },
      ],
    },
    {
      path: "/chat",
      title: "CHAT",
      type: "sub",
      icontype: "mdi mdi-chat-outline icon-sidenav",
      isCollapsed: true,
      is_new: true,
      children: [
        {
          path: "chat-history",
          title: "HISTORICO",
          type: "link",
          permission: "@VisualizarHistoricoChat",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            { path: "service-queue", title: "FILA_ATENDIMENTO", type: "link", permission: "@VisualizarWhatsapp" }
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            { path: "configuration-list", title: "Chat", type: "link", permission: "@VisualizarWhatsapp" },
            {
              path: "whatsapp-list",
              title: "WhatsApp",
              type: "link",
              permission: "@VisualizarWhatsapp",
            },
            {
              path: "telegram-list",
              title: "Telegram",
              type: "link",
              permission: "@VisualizarTelegram",
            },
            {
              path: "workflow-chat",
              title: "Workflow",
              type: "link",
              permission: '@PossuiWorkflows',
              is_new: true
            }
          ],
        },
      ],
    },
    {
      path: "/calendar",
      title: "TAREFAS",
      type: "sub",
      icontype: "mdi mdi-calendar-check-outline icon-sidenav",
      isCollapsed: true,
      children: [
        { path: "schedule", title: "MINHAS_TAREFAS", type: "link" },
        {
          path: "team-schedule",
          title: "TAREFAS_EQUIPE",
          type: "link",
          permission: "@VisualizarTodasTarefas",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            { path: "task-type-list", title: "TIPOS_TAREFAS", type: "link" },
          ],
        },
      ],
    },
    {
      path: "/projects",
      title: "PROJETOS",
      type: "sub",
      icontype: "icon-projects-white",
      isCollapsed: true,
      children: [
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            { path: "list", title: "LISTAGEM", type: "link" }
          ]
        }
      ],
      is_new: true
    },
    {
      path: "/reports",
      title: "RELATORIOS",
      type: "sub",
      icontype: "mdi mdi-chart-multiple icon-sidenav",
      isCollapsed: true,
      children: [
        { path: "simple", title: "RELATORIOS", type: "link" },
        {
          path: "custom",
          title: "RELATORIO_PERSONALIZADO",
          type: "link",
          permission: "@VisualizarRelatorioPersonalizado",
        },
        { path: "billing-report", title: "RELATORIO_FATURAMENTO", type: "link" }
      ],
    },
    {
      path: "/revenues",
      title: "FATURAMENTO",
      type: "sub",
      icontype: "mdi mdi-cash-multiple icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "conference-tickets",
          title: "CONFERENCIA_FATURAMENTO",
          type: "link",
          permission: "@VisualizarFinanceiro",
        },
        {
          path: "charges",
          title: "GERENCIAMENTO_COBRANCAS",
          type: "link",
          permission: "@VisualizarFinanceiro",
        },
        {
          path: "contracts",
          title: "GERENCIAMENTO_CONTRATOS",
          type: "link",
          permission: "@VisualizarContrato",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            {
              path: "service-catalog",
              title: "CATALOGO_SERVICOS",
              type: "link",
              permission: "@VisualizarFinanceiro",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "contracts-setup",
              title: "CONTRATOS",
              type: "link",
              permission: "@VisualizarFinanceiro",
            },
            {
              path: "default",
              title: "BLOQUEIO_INADIMPLENCIA",
              type: "link",
              permission: "@VisualizarConfiguracaoInadimplencia",
            },
          ],
        },
      ],
    },
    {
      path: "/library",
      title: "BIBLIOTECA",
      type: "sub",
      icontype: "mdi mdi-book-open-page-variant-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "knowledge-base",
          title: "BASE_CONHECIMENTO",
          type: "link",
          permission: "@VisualizarBaseConhecimento",
        }
      ],
    },
    {
      path: "/registration",
      title: "CADASTROS",
      type: "sub",
      icontype: "mdi mdi-folder-edit-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "profiles-list",
          title: "PERFIS_OPERADORES",
          type: "link",
          permission: "@VisualizaPerfilUsuario",
        },
        {
          path: "operator-list",
          title: "OPERADORES",
          type: "link",
          permission: "@VisualizaUsuario",
        },
        {
          path: "team-list",
          title: "EQUIPES_OPERADORES",
          type: "link",
          permission: "@VisualizaEquipes",
        },
        {
          path: "customers-list",
          title: "CLIENTES",
          type: "link",
          permission: "@VisualizaCliente",
        },
        {
          path: "sector-list",
          title: "SETORES_SOLICITANTES",
          type: "link",
          permission: "@VisualizarSetor",
        },
        {
          path: "holiday-list",
          title: "FERIADOS",
          type: "link",
          permission: "@VisualizarFeriados",
        },
        {
          path: "office-hour",
          title: "EXPEDIENTES",
          type: "link",
          permission: "@VisualizarExpediente"
        },
        {
          path: "",
          title: "DOCUMENTOS",
          type: "group",
          children: [
            {
              path: "graphic-art-list",
              title: "ARTE_GRAFICA",
              type: "link",
              permission: "@VisualizarDocumentoArteGrafica",
            },
            {
              path: "contract-list",
              title: "CONTRATOS",
              type: "link",
              permission: "@VisualizarDocumentoContratos",
            },
            {
              path: "miscellaneous-documents-list",
              title: "DOCUMENTOS_DIVERSOS",
              type: "link",
              permission: "@VisualizarDocumentoDiversos",
            },
          ],
        },
      ],
    },
    {
      path: "/settings",
      title: "CONFIGURACOES",
      type: "sub",
      icontype: "mdi mdi-cog-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "white-label",
          title: "PERSONALIZACAO_WHITE_LABEL",
          type: "link",
        },
        { path: "key-api", title: "TOKEN_API", type: "link" },
        {
          path: "integrations",
          title: "INTEGRACOES",
          type: "link",
          permission: "@VisualizarIntegracoes",
        },
        {
          path: "external-integrations",
          title: "INTEGRACOES_GERENCIADORES_DOMINIOS",
          type: "link",
          permission: "@VisualizarIntegracoesExternas",
        },
        {
          path: "two-factor-authentication",
          title: "AUTENTICACAO_DOIS_FATORES",
          type: "link",
          permission: "@Visualizar2FA",
        },
      ],
    },
    {
      path: "/privacy",
      title: "PROTECAO_GERAL_DADOS",
      type: "sub",
      icontype: "mdi mdi-shield-key-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "list",
          title: "CONFIGURAR_CONSENTIMENTO_COOKIE",
          type: "link",
          permission: "@VisualizarLGPD",
        },
      ],
    }
  ];
  userCompleto: RouteInfo[] = [
    {
      path: "/dashboards",
      title: "Dashboards",
      type: "sub",
      icontype: "mdi mdi-view-dashboard-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "",
          title: "DASH_CANAIS_ATENDIMENTO",
          type: "group",
          children: [
            {
              path: "service-channels",
              title: "GERAL",
              type: "link"
            },
            {
              path: "users-status",
              title: "DASH_STATUS_DO_OPERADOR",
              type: "link"
            }
          ],
        },
        {
          path: "",
          title: "CHAT",
          type: "group",
          children: [
            {
              path: "chat",
              title: "GERAL",
              type: "link",
              permission: "@VisualizarDashboardChat",
            },
          ],
        },
        {
          path: "",
          title: "Tickets",
          type: "group",
          children: [
            {
              path: "dashboard",
              title: "GERAL",
              type: "link",
              permission: "@VisualizarDashboard",
            },
            {
              path: "main",
              title: "PRINCIPAL",
              type: "link",
              permission: "@VisualizarDashboardPrincipal",
            },
            {
              path: "sla",
              title: "SLA",
              type: "link",
              permission: "@VisualizarDashBoardSLA",
            },
            {
              path: "tables",
              title: "MESAS_TRABALHO",
              type: "link",
              permission: "@VisualizarMesas",
            },
            {
              path: "ticket",
              title: "FLUXO_ATENDIMENTO",
              type: "link",
              permission: "@VisualizarTicket",
            },
            {
              path: "technician",
              title: "OPERADORES",
              type: "link",
              permission: "@VisualizarTecnico",
            },
            {
              path: "tasks",
              title: "TAREFAS",
              type: "link",
              permission: "@VisualizarTarefas",
            },
            {
              path: "ticket-classification",
              title: "AVALIACAO_EXTERNA",
              type: "link",
              permission: "@VisualizarClassificacaoCliente",
            },
            {
              path: "internal-classification",
              title: "AVALIACAO_INTERNA",
              type: "link",
              permission: "@VisualizarDashboardClassificacaoInterna",
            },
            {
              path: "approval",
              title: "APROVACAO",
              type: "link",
              permission: "@VisualizarDashboardClassificacaoInterna",
            },
          ],
        },
        {
          path: "",
          title: "INVENTARIO",
          type: "group",
          children: [
            {
              path: "vulnerability",
              title: "COL_VULNERABILIDADE",
              type: "link",
              permission: "@VisualizarVulnerabilidade",
            },
            {
              path: "inventory",
              title: "GERAL",
              type: "link",
              permission: "@VisualizarInventario",
            },
            {
              path: "link-monitoring",
              title: "MONITORAMENTO_LINK",
              type: "link",
              permission: "@VisualizarMonitoramentoLink",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "tv-mode-groups",
              title: "GRUPOS_MODO_TV",
              type: "link",
              permission: "@VisualizarModoTv",
            },
          ],
        },
      ],
    },
    {
      path: "/tickets",
      title: "TICKETS",
      type: "sub",
      icontype: "mdi mdi-ticket-confirmation-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "list",
          title: "LISTAGEM_DE_TICKETS",
          type: "link",
          permission: "@VisualizarTickets",
        },
        {
          path: "tickets-withheld",
          title: "LISTAGEM_TICKETS_RETIDOS",
          type: "link",
          permission: "@VisualizarChamadoPorEmail",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            {
              path: "checklist",
              title: "CHECKLIST",
              type: "link",
              permission: "@VisualizarChecklist",
            },
            {
              path: "template-configuration",
              title: "TEMPLATES",
              type: "link",
              permission: "@VisualizarTemplateComentarios",
            },
            {
              path: "ticket-opening-form",
              title: "FORMULARIO_DE_ABERTURA",
              type: "link",
              permission: "@VisualizarFormularioAberturaTicket",
            },
            {
              path: "pause-reason",
              title: "MENU_MOTIVO_PAUSA",
              type: "link",
              permission: "@VisualizarMotivoPausa",
            },
            {
              path: "mail-box-configuration",
              title: "CAIXA_EMAIL",
              type: "link",
              permission: "@VisualizarChamadoPorEmail",
            },
            {
              path: "ticket-type-list",
              title: "TIPOS_TICKETS",
              type: "link",
              permission: "@VisualizarTipoChamado",
            },
            // {
            //   path: "category-list",
            //   title: "CATEGORIAS",
            //   type: "link",
            //   permission: "@VisualizaCategoriaPrimaria",
            // },
            {
              path: "category-catalogue-list",
              title: "CATALOGO_CATEGORIAS",
              type: "link",
              permission: "@VisualizarCatalogoCategorias"
            },
            {
              path: "category-groups-list",
              title: "GRUPOS_CATEGORIA",
              type: "link",
              permission: "@VisualizarGrupoCategorias"
            },
            {
              path: "work-desks-list",
              title: "MESAS_TRABALHO",
              type: "link",
              permission: "@VisualizarMesaTrabalho",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "workflow",
              title: "AUTOMACAO",
              type: "link",
              permission: "@VisualizarAutomacaoMonitoramento",
            },
            {
              path: "sla-settings",
              title: "SLA",
              type: "link",
              permission: "@VisualizarConfiguracaoSLA",
            },
            {
              path: "tickets-fields-configuration",
              title: "CAMPOS_CUSTOMIZAVEIS",
              type: "link",
              permission: "@VisualizarConfiguracoesCampos",
            },
            {
              path: "rules-fields",
              title: "REGRA_CAMPOS",
              type: "link",
              permission: "@VisualizarRegrasCampos",
            },
            {
              path: "satisfaction-survey",
              title: "PESQUISA_SATISFACAO",
              type: "link",
              permission: "@VisualizarPesquisaSatisfacao",
            },
            {
              path: "opening",
              title: "ABERTURA_TICKET",
              type: "link",
              permission: "@VisualizarAberturaChamado",
            },
            {
              path: "closing",
              title: "FINALIZACAO_TICKET",
              type: "link",
              //permission: "@VisualizarAberturaChamado",
            },
            {
              path: "setting-emails",
              title: "DISPARO_EMAIL",
              type: "link",
              permission: "@VisualizarConfigDisparoEmail",
            },
            {
              path: "approval",
              title: "APROVACAO",
              type: "link",
              permission: "@EditarAprovacaoTicket",
            },
          ],
        },
      ],
    },
    {
      path: "/chat",
      title: "CHAT",
      type: "sub",
      icontype: "mdi mdi-chat-outline icon-sidenav",
      isCollapsed: true,
      is_new: true,
      children: [
        {
          path: "chat-history",
          title: "HISTORICO",
          type: "link",
          permission: "@VisualizarHistoricoChat",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            { path: "service-queue", title: "FILA_ATENDIMENTO", type: "link", permission: "@VisualizarWhatsapp" }
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            { path: "configuration-list", title: "Chat", type: "link", permission: "@VisualizarWhatsapp" },
            {
              path: "whatsapp-list",
              title: "WhatsApp",
              type: "link",
              permission: "@VisualizarWhatsapp",
            },
            {
              path: "telegram-list",
              title: "Telegram",
              type: "link",
              permission: "@VisualizarTelegram",
            },
            {
              path: "workflow-chat",
              title: "Workflow",
              type: "link",
              permission: '@PossuiWorkflows',
              is_new: true
            }
          ],
        },
      ],
    },
    {
      path: "/calendar",
      title: "TAREFAS",
      type: "sub",
      icontype: "mdi mdi-calendar-check-outline icon-sidenav",
      isCollapsed: true,
      children: [
        { path: "schedule", title: "MINHAS_TAREFAS", type: "link" },
        {
          path: "team-schedule",
          title: "TAREFAS_EQUIPE",
          type: "link",
          permission: "@VisualizarTodasTarefas",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            { path: "task-type-list", title: "TIPOS_TAREFAS", type: "link" },
          ],
        },
      ],
    },
    {
      path: "/projects",
      title: "PROJETOS",
      type: "sub",
      icontype: "icon-projects-white",
      isCollapsed: true,
      children: [
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            { path: "list", title: "LISTAGEM", type: "link" }
          ]
        }
      ],
      is_new: true
    },
    {
      path: "/devices",
      title: "INVENTARIO",
      type: "sub",
      icontype: "mdi mdi-cellphone-link icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "list",
          title: "DISPOSITIVOS",
          type: "link",
          permission: "@VisualizarDispositivos",
        },
        {
          path: "alert-listing",
          title: "ALERTAS",
          type: "link",
          permission: "@VisualizarListagemAlertas",
        },
        {
          path: "link-monitoring",
          title: "MONITORAMENTO_LINK",
          type: "link",
          permission: "@VisualizarMonitoramentoLink",
        },
        {
          path: "classification-programs-services",
          title: "PROGRAMAS_SERVICOS",
          type: "link",
          permission: "@VisualizarClassificacaoProgramaServico",
        },
        {
          path: "software-license-manager",
          title: "LICENCAS_SOFTWARE",
          type: "link",
          permission: "@VisualizarGerenciamentoLicencas",
        },
        {
          path: "password-manager",
          title: "COFRE_SENHAS",
          type: "link",
          permission: "@VisualizarSenha",
        },
        {
          path: "password-group",
          title: "GRUPO_SENHAS",
          type: "link",
          permission: "@VisualizarSenha",
        },
        {
          path: "antivirus",
          title: "ANTIVIRUS",
          type: "link",
          permission: "@VisualizarAntivirus",
        },
        {
          path: "removable-media",
          title: "MENU_MIDIAS_REMOVIVEIS",
          type: "link",
          permission: "@VisualizarConfiguracoesBloqueioMidiasRemoviveis",
        },
        {
          path: "network-discovery",
          title: "ESCANEAMENTO_DESCOBERTA_REDE",
          type: "link",
          permission: "@VisualizarConfiguracaoEscaneamento",
        },
        {
          path: "network-topology",
          title: "TOPOLOGIA_REDE",
          type: "link",
          permission: "@VisualizarTopologiaRede",
        },
        {
          path: "devices-loan-rental",
          title: "EMPRESTIMO_DISPOSITIVOS",
          type: "link",
          permission: "@VisualizarEmprestimo",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            {
              path: "device-type",
              title: "TIPO_DISPOSITIVO",
              type: "link",
              permission: "@VisualizarTipoDispositivo",
            },
            {
              path: "device-profile-list",
              title: "PERFIS_DISPOSITIVOS",
              type: "link",
              permission: "@VisualizarPerfilDispositivo",
            },
            {
              path: "link",
              title: "BANDA_LARGA",
              type: "link",
              permission: "@VisualizarDocumentoLink",
            },
            {
              path: "manufacturer-list",
              title: "FABRICANTES",
              type: "link",
              permission: "@VisualizarCadastroFabricante",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "alerts",
              title: "ALERTAS",
              type: "link",
              permission: "@VisualizarConfiguracaoGeralAlertas",
            },
            {
              path: "workflow",
              title: "AUTOMACAO",
              type: "link",
              permission: "@VisualizarAutomacaoDispositivos",
            },
            {
              path: "automation-alert",
              title: "AUTOMACAO_ALERTAS",
              type: "link",
              permission: "@VisualizarAutomacaoMonitoramento",
            },
            {
              path: "vulnerability",
              title: "VULNERABILIDADE",
              type: "link",
              permission: "@VisualizarCadastroVulnerabilidades",
            },
            {
              path: "remote-access",
              title: "ACESSO_REMOTO",
              type: "link",
              permission: "@VisualizarConfiguracaoAcessoRemoto",
            },
            {
              path: "automatic-update",
              title: "ATUALIZACAO_AGENTES",
              type: "link",
              permission: "@VisualizarConfiguracaoAtualizacao",
            },
          ],
        },
      ],
    },
    {
      path: "/reports",
      title: "RELATORIOS",
      type: "sub",
      icontype: "mdi mdi-chart-multiple icon-sidenav",
      isCollapsed: true,
      children: [
        { path: "simple", title: "RELATORIOS", type: "link" },
        {
          path: "custom",
          title: "RELATORIO_PERSONALIZADO",
          type: "link",
          permission: "@VisualizarRelatorioPersonalizado",
        },
        { path: "billing-report", title: "RELATORIO_FATURAMENTO", type: "link" }
      ],
    },
    {
      path: "/revenues",
      title: "FATURAMENTO",
      type: "sub",
      icontype: "mdi mdi-cash-multiple icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "conference-tickets",
          title: "CONFERENCIA_FATURAMENTO",
          type: "link",
          permission: "@VisualizarFinanceiro",
        },
        {
          path: "charges",
          title: "GERENCIAMENTO_COBRANCAS",
          type: "link",
          permission: "@VisualizarFinanceiro",
        },
        {
          path: "contracts",
          title: "GERENCIAMENTO_CONTRATOS",
          type: "link",
          permission: "@VisualizarContrato",
        },
        {
          path: "",
          title: "CADASTROS",
          type: "group",
          children: [
            {
              path: "service-catalog",
              title: "CATALOGO_SERVICOS",
              type: "link",
              permission: "@VisualizarFinanceiro",
            },
          ],
        },
        {
          path: "",
          title: "CONFIGURACAO",
          type: "group",
          children: [
            {
              path: "contracts-setup",
              title: "CONTRATOS",
              type: "link",
              permission: "@VisualizarFinanceiro",
            },
            {
              path: "default",
              title: "BLOQUEIO_INADIMPLENCIA",
              type: "link",
              permission: "@VisualizarConfiguracaoInadimplencia",
            },
          ],
        },
      ],
    },
    {
      path: "/library",
      title: "BIBLIOTECA",
      type: "sub",
      icontype: "mdi mdi-book-open-page-variant-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "knowledge-base",
          title: "BASE_CONHECIMENTO",
          type: "link",
          permission: "@VisualizarBaseConhecimento",
        },
        {
          path: "script-library",
          title: "Script",
          type: "link",
          permission: "@VisualizarBibliotecaScript",
        },
      ],
    },
    {
      path: "/registration",
      title: "CADASTROS",
      type: "sub",
      icontype: "mdi mdi-folder-edit-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "profiles-list",
          title: "PERFIS_OPERADORES",
          type: "link",
          permission: "@VisualizaPerfilUsuario",
        },
        {
          path: "operator-list",
          title: "OPERADORES",
          type: "link",
          permission: "@VisualizaUsuario",
        },
        {
          path: "team-list",
          title: "EQUIPES_OPERADORES",
          type: "link",
          permission: "@VisualizaEquipes",
        },
        {
          path: "customers-list",
          title: "CLIENTES",
          type: "link",
          permission: "@VisualizaCliente",
        },
        // {
        //   path: "contacts",
        //   title: "CONTATOS",
        //   type: "link",
        //   permission: "@VisualizarContatos",
        // },
        {
          path: "sector-list",
          title: "SETORES_SOLICITANTES",
          type: "link",
          permission: "@VisualizarSetor",
        },
        {
          path: "holiday-list",
          title: "FERIADOS",
          type: "link",
          permission: "@VisualizarFeriados",
        },
        {
          path: "office-hour",
          title: "EXPEDIENTES",
          type: "link",
          permission: "@VisualizarExpediente"
        },
        {
          path: "",
          title: "DOCUMENTOS",
          type: "group",
          children: [
            {
              path: "graphic-art-list",
              title: "ARTE_GRAFICA",
              type: "link",
              permission: "@VisualizarDocumentoArteGrafica",
            },
            {
              path: "contract-list",
              title: "CONTRATOS",
              type: "link",
              permission: "@VisualizarDocumentoContratos",
            },
            {
              path: "miscellaneous-documents-list",
              title: "DOCUMENTOS_DIVERSOS",
              type: "link",
              permission: "@VisualizarDocumentoDiversos",
            },
          ],
        },
      ],
    },
    {
      path: "/settings",
      title: "CONFIGURACOES",
      type: "sub",
      icontype: "mdi mdi-cog-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "white-label",
          title: "PERSONALIZACAO_WHITE_LABEL",
          type: "link",
        },
        { path: "key-api", title: "TOKEN_API", type: "link" },
        {
          path: "integrations",
          title: "INTEGRACOES",
          type: "link",
          permission: "@VisualizarIntegracoes",
        },
        {
          path: "external-integrations",
          title: "INTEGRACOES_GERENCIADORES_DOMINIOS",
          type: "link",
          permission: "@VisualizarIntegracoesExternas",
        },
        {
          path: "scanning",
          title: "ESCANEAMENTO",
          type: "link",
          permission: "@VisualizarConfiguracaoEscaneamento",
        },
        {
          path: "two-factor-authentication",
          title: "AUTENTICACAO_DOIS_FATORES",
          type: "link",
          permission: "@Visualizar2FA",
        },
      ],
    },
    {
      path: "/privacy",
      title: "PROTECAO_GERAL_DADOS",
      type: "sub",
      icontype: "mdi mdi-shield-key-outline icon-sidenav",
      isCollapsed: true,
      children: [
        {
          path: "list",
          title: "CONFIGURAR_CONSENTIMENTO_COOKIE",
          type: "link",
          permission: "@VisualizarLGPD",
        },
      ],
    },
    {
      path: "/settings/agents-download",
      title: "DOWNLOAD_AGENTES",
      type: "link",
      icontype: "",
      iconsvg: "./assets/img/icons/svg/monitor-arrow-down-variant.svg",
      isCollapsed: false,
      permission: "@VisualizarAgentsUpdates",
    },
  ];
  tipoAcesso: number | null = this.tokenService.getTipoPerfilUsuario();
  componentesUserChat: string[] = [
    "CanaisAtendimentoComponent",
    "UsersStatusComponent",
    "ChatComponent",
    "TvGroupsComponent",
    "HistoricoChatComponent",
    // "ListagemContatosComponent", Migrado para Cadastros
    "ServiceQueueComponent",
    "ListagemConfiguracaoComponent",
    "EditarWidgetComponent",
    "WhatsappListComponent",
    "WhatsappComponent",
    "TelegramListComponent",
    "TelegramComponent",
    "ListagemComponent",
    "ConfiguracaoComponent",
    "BaseConhecimentoComponent",
    "ListagemOperadorComponent",
    "EditarOperadorComponent",
    "ListagemClienteComponent",
    "EditarClienteComponent",
    "ListagemFeriadoComponent",
    "ExpedientesComponent",
    "ListagemArteGraficaComponent",
    "ListagemContratosComponent",
    "ListagemDocumentosDiversosComponent",
    "EmpresaComponent",
    // "ContatosComponent"
  ];
  componentesUserTicket: string[] = [
    "CanaisAtendimentoComponent",
    "UsersStatusComponent",
    "ChatComponent",
    "DashboardComponent",
    "PrincipalComponent",
    "SlaComponent",
    "MesasComponent",
    "TicketComponent",
    "TecnicoComponent",
    "TarefasComponent",
    "ClassificacaoTicketClienteComponent",
    "ClassificacaoInternaComponent",
    "AprovacaoComponent",
    "TvGroupsComponent",
    "ListagemTicketsComponent",
    "EditarTicketComponent",
    "TicketsRetidosComponent",
    "ChecklistComponent",
    "ConfiguracaoTemplateComponent",
    "FormularioAberturaListagemComponent",
    "FormularioAberturaEditarComponent",
    "ListagemMotivoPausaComponent",
    "ListagemCaixaEmailTicketComponent",
    "ListagemTicketComponent",
    "CategoryCatalogueListComponent",
    "CategoryGroupsListComponent",
    "ListagemMesasComponent",
    "AutomacaoComponent",
    "ConfiguracaoSlaTicketsComponent",
    "NovoConfiguracaoCamposTicketsComponent",
    "RegraCamposCustomizaveisComponent",
    "PesquisaSatisfacaoComponent",
    "AberturaComponent",
    "FinalizacaoTicketComponent",
    "ConfiguracaoEmailComponent",
    "AprovacaoTicketsComponent",
    "HistoricoChatComponent",
    // "ListagemContatosComponent", Migrado para Cadastros
    "ServiceQueueComponent",
    "ListagemConfiguracaoComponent",
    "EditarWidgetComponent",
    "WhatsappListComponent",
    "WhatsappComponent",
    "TelegramListComponent",
    "TelegramComponent",
    "ListagemComponent",
    "ConfiguracaoComponent",
    "MinhaAgendaComponent",
    "MinhaAgendaDiarioComponent",
    "ListagemTarefaComponent",
    "ListagemProjetosComponent",
    "EditarProjetosComponent",
    "RelatorioSimplesComponent",
    "RelatorioPersonalizadoComponent",
    "RelatorioPersonalizadoEditarComponent",
    "RelatorioFaturamentoComponent",
    "ConferenciaComponent",
    "ListaConferenciaTicketsComponent",
    "CobrancasComponent",
    "ListagemContratoComponent",
    "EditarContratoComponent",
    "CatalogoDeServicosComponent",
    "ConfiguracaoContratoComponent",
    "InadimplenciaComponent",
    "BaseConhecimentoComponent",
    "ListagemOperadorComponent",
    "EditarOperadorComponent",
    "ListagemClienteComponent",
    "EditarClienteComponent",
    "ListagemEquipeComponent",
    "ListagemSetorComponent",
    "ListagemFeriadoComponent",
    "ExpedientesComponent",
    "ListagemArteGraficaComponent",
    "ListagemContratosComponent",
    "ListagemDocumentosDiversosComponent",
    "WhiteLabelComponent",
    "ChaveApiComponent",
    "IntegracoesComponent",
    "IntegracoesExternasComponent",
    "AutenticacaoDoisFatoresComponent",
    "ListagemConfiguracaoComponent",
    "EditarConfiguracaoComponent",
    "EmpresaComponent",
    "TvEditGroupComponent",
    "RelatorioPersonalizadoVisualizarComponent",
    "RelatorioPivotGridComponent"
  ];

  constructor(
    private tokenService: TokenService
  ) { }

  rotasPorPerfil(): RouteInfo[] {
    switch (this.tipoAcesso) {
      case 3:
        return this.userChat;
      case 2:
        return this.userTicket;
      case 1:
        return this.userCompleto;
      default:
        return this.userCompleto;
    }
  }

  verificaPerfilAcessaComponente(rota: string): boolean {
    switch (this.tipoAcesso) {
      case 3:
        return this.componentesUserChat.includes(rota);
      case 2:
        return this.componentesUserTicket.includes(rota);
      case 1:
        return true;
      default:
        return true;
    }
  }
}