import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
    providedIn: 'root'
})
export class AgentesService {

    private apiUrl = environment.apiGestorUrlLocal;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken() }),
        params: {}
    };

    constructor(private http: HttpClient, private tokenService: TokenService) { }

    teste401(): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}teste-401`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    MSI(clientId): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}api/Install/busca/msi/${clientId}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    msiNovo(clientId, arquitetura): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}api/Install/busca/msi-novo/${clientId}/${arquitetura}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }
}
