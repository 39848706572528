import { InputComponent } from './../input/input.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalCadastroClienteComponent } from './modal-cadastro-cliente/modal-cadastro-cliente.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { SelectsModule } from '../selects/selects.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CriarPerfilUsuarioComponent } from "./criar-perfil-usuario/criar-perfil-usuario.component";
import { EditarPerfilUsuarioComponent } from "./editar-perfil-usuario/editar-perfil-usuario.component";
import { CriarTipoTarefaComponent } from "./criar-tipo-tarefa/criar-tipo-tarefa.component";
import { EditarTipoTarefaComponent } from "./editar-tipo-tarefa/editar-tipo-tarefa.component";
import { CriarTipoTicketComponent } from "./criar-tipo-ticket/criar-tipo-ticket.component";
import { EditarTipoTicketComponent } from './editar-tipo-ticket/editar-tipo-ticket.component';
import { DetalhesArmazenaComponent } from './detalhes-armazena/detalhes-armazena.component';
import { DetalhesRedeComponent } from './detalhes-rede/detalhes-rede.component';
import { DetalhesDispositivoAlertasComponent } from './detalhes-dispositivo-alertas/detalhes-dispositivo-alertas.component';
import { DetalhesDispositivoServicosComponent } from './detalhes-dispositivo-servicos/detalhes-dispositivo-servicos.component';
import { DetalhesDispositivoSoftwaresComponent } from './detalhes-dispositivo-softwares/detalhes-dispositivo-softwares.component';
import { SenhaVerificarUsuarioComponent } from './senha-verificar-usuario/senha-verificar-usuario.component';
import { CriarTerceirizadaComponent } from './criar-terceirizada/criar-terceirizada.component';
import { FeriadoComponent } from './feriado/feriado.component';
import { ContatoComponent } from './contato/contato.component';
import { ModalEnderecoClienteComponent } from './modal-endereco-cliente/modal-endereco-cliente.component';
import { ModalAdicionarEquipeClienteComponent } from './modal-adicionar-equipe-cliente/modal-adicionar-equipe-cliente.component';
import { ModalEditarAlertaClienteComponent } from './modal-editar-alerta-cliente/modal-editar-alerta-cliente.component';
import { ModalUsuarioClienteComponent } from './modal-usuario-cliente/modal-usuario-cliente.component';
import { ModalSetorClienteComponent } from './modal-setor-cliente/modal-setor-cliente.component';
import { ModalCategoriaClienteComponent } from './modal-categoria-cliente/modal-categoria-cliente.component';
import { ModalTicketProgramadoComponent } from './modal-ticket-programado/modal-ticket-programado.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { SubcategoriaComponent } from './subcategoria/subcategoria.component';
import { ModalBloquearClienteComponent } from './modal-bloquear-cliente/modal-bloquear-cliente.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { ModalImportarClientesComponent } from './modal-importar-clientes/modal-importar-clientes.component';
import { MesasDeTrabalhoComponent } from './mesas-de-trabalho/mesas-de-trabalho.component';
import { ModalContratoComponent } from './modal-contrato/modal-contrato.component';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalTipoCobrancaComponent } from './modal-tipo-cobranca/modal-tipo-cobranca.component';
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ModalCobrancaHoraComponent } from './modal-cobranca-hora/modal-cobranca-hora.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ModalCobrancaTicketComponent } from './modal-cobranca-ticket/modal-cobranca-ticket.component';
import { ModalCobrancaOutrosComponent } from './modal-cobranca-outros/modal-cobranca-outros.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { EquipeComponent } from './equipe/equipe.component';
import { FluxoComponent } from './fluxo/fluxo.component';
import { TagInputModule } from 'ngx-chips';
import { PassosComponent } from './passos/passos.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalCadastroSenhaDispositivoComponent } from './modal-cadastro-senha-dispositivo/modal-cadastro-senha-dispositivo.component';
import { ModalEditarSenhaDispositivoComponent } from './modal-editar-senha-dispositivo/modal-editar-senha-dispositivo.component';
import { AtualizarEmMassaComponent } from './modal-atualizar-em-massa/modal-atualizar-em-massa.component';
import { DetalhesChatHistoricoComponent } from './detalhes-chat-historico/detalhes-chat-historico.component';
import { ChatContatoComponent } from './chat-contato/chat-contato.component';
import { EditarVulnerabilidadeComponent } from './editar-vulnerabilidade/editar-vulnerabilidade.component';
import { InputTagsPadraoModule } from '../input-tags-padrao/input-tags-padrao.module';
import { PerfilDispositivoComponent } from './perfil-dispositivo/perfil-dispositivo.component';
import { PerfilDispositivoSoftwareComponent } from './perfil-dispositivo-software/perfil-dispositivo-software.component';
import { BandaLargaComponent } from './banda-larga/banda-larga.component';
import { ModalAcessoRemotoComponent } from './modal-acesso-remoto/modal-acesso-remoto.component';
import { ModalCriarDispositivoComponent } from './modal-criar-dispositivo/modal-criar-dispositivo.component';
import { ModalAtualizacaoEditarComponent } from './modal-atualizacao-editar/modal-atualizacao-editar.component';
import { ModalAtualizacaoSalvarComponent } from './modal-atualizacao-salvar/modal-atualizacao-salvar.component';
import { ModalAcessoRemotoSalvarComponent } from './modal-acesso-remoto-salvar/modal-acesso-remoto-salvar.component';

import { DocumentosDiversosComponent } from './documentos-diversos/documentos-diversos.component';
import { PipesModule } from "src/app/shared/validators/pipes/pipes.module";
import { ContratosComponent } from './contratos/contratos.component';
import { ArteGraficaComponent } from './arte-grafica/arte-grafica.component';
import { ModalTicketLogsComponent } from './modal-ticket-logs/modal-ticket-logs.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { FiltrosModule } from '../filtros/filtros.module';
import { RouterModule } from '@angular/router';
import { NovaConfiguracaoTicketComponent } from './nova-configuracao-ticket/nova-configuracao-ticket.component';
import { EditarConfiguracaoAberturaComponent } from './editar-configuracao-abertura/editar-configuracao-abertura.component';
import { MonitoramentoLinkComponent } from './monitoramento-link/monitoramento-link.component';
import { MonitoramentoLinkPassosComponent } from './monitoramento-link-passos/monitoramento-link-passos.component';
import { MonitoramentoLinkGraficoComponent } from './monitoramento-link-grafico/monitoramento-link-grafico.component';
import { ModalTarefaComponent } from './modal-tarefa/modal-tarefa.component';
import { CriarEscaneamentoComponent } from './criar-escaneamento/criar-escaneamento.component';
import { EditarEscaneamentoComponent } from './editar-escaneamento/editar-escaneamento.component';
import { EditarConfiguracaoAlertasComponent } from './editar-configuracao-alertas/editar-configuracao-alertas.component';
import { ModalAberturaTicketComponent } from './modal-abertura-ticket/modal-abertura-ticket.component';
import { AntivirusPadraoCriarModalComponent } from './antivirus-padrao-criar-modal/antivirus-padrao-criar-modal.component';
import { AntivirusPersonalizadoCriarModalComponent } from './antivirus-personalizado-criar-modal/antivirus-personalizado-criar-modal.component';
import { AntivirusPersonalizadoEditarModalComponent } from './antivirus-personalizado-editar-modal/antivirus-personalizado-editar-modal.component';
import { AntivirusPadraoEditarModalComponent } from './antivirus-padrao-editar-modal/antivirus-padrao-editar-modal.component';
import { ChecklistModalEditarComponent } from './checklist-modal-editar/checklist-modal-editar.component';
import { ChecklistModalCriarComponent } from './checklist-modal-criar/checklist-modal-criar.component';
import { ChecklistModalEditarCampoModalComponent } from './checklist-modal-editar-campo-modal/checklist-modal-editar-campo-modal.component';
import { NovoGatilhoTicketModalComponent } from './novo-gatilho-ticket-modal/novo-gatilho-ticket-modal.component';
import { NovoGatilhoSlaModalComponent } from './novo-gatilho-sla-modal/novo-gatilho-sla-modal.component';
import { EditarGatilhoSlaModalComponent } from './editar-gatilho-sla-modal/editar-gatilho-sla-modal.component';
import { EditarGatilhoTicketModalComponent } from './editar-gatilho-ticket-modal/editar-gatilho-ticket-modal.component';
import { ModalPausarChamadoComponent } from './modal-pausar-chamado/modal-pausar-chamado.component';
import { ModalCriarGrupoComponent } from './modal-criar-grupo/modal-criar-grupo.component';
import { ModalComentarioTicketComponent } from './modal-comentario-ticket/modal-comentario-ticket.component';
import { ModalOpenAITemplateComponent } from './modal-openai-template/modal-openai-template.component';
import { ModalEnviarEmailComponent } from './modal-enviar-email/modal-enviar-email.component';
import { ModalChecklistTicketComponent } from './modal-checklist-ticket/modal-checklist-ticket.component';
import { ModalPecasChamadoComponent } from './modal-pecas-chamado/modal-pecas-chamado.component';
import { ModalHorasTicketComponent } from './modal-horas-ticket/modal-horas-ticket.component';
import { ModalApontarHorasTicketComponent } from './modal-apontar-horas-ticket/modal-apontar-horas-ticket.component';
import { ModalOrdemServicoComponent } from './modal-ordem-servico/modal-ordem-servico.component';
import { ModalConciliarTicketComponent } from './modal-conciliar-ticket/modal-conciliar-ticket.component';
import { ModalFinalizarChamadoComponent } from './modal-finalizar-chamado/modal-finalizar-chamado.component';
import { ModalReabrirTicketComponent } from './modal-reabrir-ticket/modal-reabrir-ticket.component';
import { ModalAvaliacaoTicketComponent } from './modal-avaliacao-ticket/modal-avaliacao-ticket.component';
import { TableSelectModule } from "./../table-select/table-select.module";
import { MapaComponent } from './mapa/mapa.component';
import { LicencaSoftwareVisualizarComponent } from './licenca-software-visualizar/licenca-software-visualizar.component';
import { LicencaSoftwareEditarComponent } from './licenca-software-editar/licenca-software-editar.component';
import { LicencaSoftwareEditarDispositivosVisualizarComponent } from './licenca-software-editar-dispositivos-visualizar/licenca-software-editar-dispositivos-visualizar.component';
import { NovaConfigurcaoInadimplenciaComponent } from './nova-configurcao-inadimplencia/nova-configurcao-inadimplencia.component';
import { EditarConfigurcaoInadimplenciaComponent } from './editar-configurcao-inadimplencia/editar-configurcao-inadimplencia.component';
import { EmprestimoDispositivoComponent } from './emprestimo-dispositivo/emprestimo-dispositivo.component';
import { LocarDispositivoComponent } from './locar-dispositivo/locar-dispositivo.component';
import { EmprestimoDispositivoHistoricoComponent } from './emprestimo-dispositivo-historico/emprestimo-dispositivo-historico.component';
import { CriarCobrancaPorHoraComponent } from './criar-cobranca-por-hora/criar-cobranca-por-hora.component';
import { EditarCobrancaPorHoraComponent } from './editar-cobranca-por-hora/editar-cobranca-por-hora.component';
import { CobrancasModalPdfPorEmailComponent } from './cobrancas-modal-pdf-por-email/cobrancas-modal-pdf-por-email.component';
import { CobrancasModalPagarParcelaDescricaoComponent } from './cobrancas-modal-pagar-parcela-descricao/cobrancas-modal-pagar-parcela-descricao.component';
import { CobrancasModalVisualizarContratoComponent } from './cobrancas-modal-visualizar-contrato/cobrancas-modal-visualizar-contrato.component';
import { CobrancasModalNovaCobrancaComponent } from './cobrancas-modal-nova-cobranca/cobrancas-modal-nova-cobranca.component';

import { ContratoLogsComponent } from './contrato-logs/contrato-logs.component';
import { ModalEditarCatalogoServicoComponent } from './modal-editar-catalogo-servico/modal-editar-catalogo-servico.component';
import { ModalNovoCatalogoServicoComponent } from './modal-novo-catalogo-servico/modal-novo-catalogo-servico.component';
import { ModalListaContatosClienteComponent } from './modal-lista-contatos-cliente/modal-lista-contatos-cliente.component';
import { ModalCriarContatoClienteComponent } from './modal-criar-contato-cliente/modal-criar-contato-cliente.component';
import { ModalMapaOperadorComponent } from './modal-mapa-operador/modal-mapa-operador.component';
import { CaixaEmailComponent } from './caixa-email/caixa-email.component';
import { CaixaEmailHistoricoComponent } from './caixa-email-historico/caixa-email-historico.component';
import { SeguirClienteComponent } from './seguir-cliente/seguir-cliente.component';
import { MilvusTreePadraoModule } from '../milvus-tree-padrao/milvus-tree-padrao.module';
import { ModalConfigPermissaoPerfilComponent } from './modal-config-permissao-perfil/modal-config-permissao-perfil.component';
import { ModalPendenciasFinalizarChamadoComponent } from './modal-pendencias-finalizar-chamado/modal-pendencias-finalizar-chamado.component';

import { EditarArtigoBaseConhecimentoComponent } from './editar-artigo-base-conhecimento/editar-artigo-base-conhecimento.component';
import { NovaPastaBaseConhecimentoComponent } from './nova-pasta-base-conhecimento/nova-pasta-base-conhecimento.component';
import { NovoArtigoBaseConhecimentoComponent } from './novo-artigo-base-conhecimento/novo-artigo-base-conhecimento.component';
import { MinhaFaturaPlanoComponent } from './minha-fatura-plano/minha-fatura-plano.component';
import { PaginationModule } from '../pagination/pagination.module'
import { NovaChaveApiModalComponent } from './nova-chave-api-modal/nova-chave-api-modal.component';
import { NovoScriptBibliotecaModalComponent } from './novo-script-biblioteca-modal/novo-script-biblioteca-modal.component';
import { EditarScriptBibliotecaModalComponent } from './editar-script-biblioteca-modal/editar-script-biblioteca-modal.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ModalEditarAssuntoTicketComponent } from './modal-editar-assunto-ticket/modal-editar-assunto-ticket.component';
import { ModalAdicionarTemplateComponent } from './modal-adicionar-template/modal-adicionar-template.component';
import { ModalTicketRetidoBloquearEmailComponent } from './modal-ticket-retido-bloquear-email/modal-ticket-retido-bloquear-email.component';
import { ModalTicketRetidoLiberarEmailComponent } from './modal-ticket-retido-liberar-email/modal-ticket-retido-liberar-email.component';
import { ModalTicketRetidoVisualizarChamadoComponent } from './modal-ticket-retido-visualizar-chamado/modal-ticket-retido-visualizar-chamado.component';
import { ModalListaTarefasChamadoComponent } from './modal-lista-tarefas-chamado/modal-lista-tarefas-chamado.component';
import { ModalDashboardAdicionarConfiguracaoDashboardComponent } from './modal-dashboard-adicionar-configuracao-dashboard/modal-dashboard-adicionar-configuracao-dashboard.component';
import { WorkflowAutomacaoComponent } from './workflow-automacao/workflow-automacao.component';
import { WorkflowCondicaoComponent } from './workflow-condicao/workflow-condicao.component';
import { WorkflowAcaoComponent } from './workflow-acao/workflow-acao.component';
import { ModalListaConferenciaTicketsComponent } from './modal-lista-conferencia-tickets/modal-lista-conferencia-tickets.component';
import { DatepickerPadraoModule } from '../datepicker-padrao/datepicker-padrao.module';
import { ModalFiltroAvancadoComponent } from './modal-filtro-avancado/modal-filtro-avancado.component';
import { ModalNovoFiltroAvancadoComponent } from './modal-novo-filtro-avancado/modal-novo-filtro-avancado.component';
import { ModalEditarFiltroAvancadoComponent } from './modal-editar-filtro-avancado/modal-editar-filtro-avancado.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ModalSalvarTemplateComponent } from './modal-salvar-template/modal-salvar-template.component';
import { ModalMacrosTemplateComponent } from './modal-macros-template/modal-macros-template.component';
import { ViewerTransferenciaComponent } from './modal-viewer-transferencia/viewertransferencia.component';
import { ViewerComponent } from './modal-Viewer/viewer.component';
import { ModalVisualizarTicketComponent } from './modal-visualizar-ticket/modal-visualizar-ticket.component';
import { ModalNovaClassificacaoComponent } from './modal-nova-classificacao/modal-nova-classificacao.component';
import { ModalEditarClassificacaoComponent } from './modal-editar-classificacao/modal-editar-classificacao.component';
import { WidgetComponent } from './widget/widget.component';
import { ModalExpedienteEmpresaComponent } from './modal-expediente-empresa/modal-expediente-empresa.component';
import { FilaAtendimentoComponent } from './fila-atendimento/fila-atendimento.component';
import { TabulacaoChatComponent } from './tabulacao-chat/tabulacao-chat.component';
import { ModalWhiteLabelOnlineComponent } from './modal-white-label-online/modal-white-label-online.component';
import { ModalWhiteLabelOfflineComponent } from './modal-white-label-offline/modal-white-label-offline.component';
import { ModalConfigurarIntegracoesComponent } from './modal-configurar-integracoes/modal-configurar-integracoes.component';
import { ModalConfigurarIntegracoesEspectraRamalComponent } from './modal-configurar-integracoes-espectra-ramal/modal-configurar-integracoes-espectra-ramal.component';
import { ModalEditarIntegracoesEspectraRamalComponent } from './modal-editar-integracoes-espectra-ramal/modal-editar-integracoes-espectra-ramal.component';
import { ModalEditarDadosGeraisComponent } from './modal-editar-dados-gerais/modal-editar-dados-gerais.component';
import { ModalWorkflowDispositivoComponent } from './modal-workflow-dispositivo/modal-workflow-dispositivo.component';
import { ModalWorkflowQuandoComponent } from './modal-workflow-quando/modal-workflow-quando.component';
import { ModalWorkflowCondicoesComponent } from './modal-workflow-condicao/modal-workflow-condicao.component';
import { ModalWorkflowScriptComponent } from './modal-workflow-script/modal-workflow-script.component';
import { ModalWorkflowServicosComponent } from './modal-workflow-servicos/modal-workflow-servicos.component';
import { ModalWorkflowAcaoComponent } from './modal-workflow-acao/modal-workflow-acao.component';
import { ChatViewerComponent } from './chat-viewer/chat-viewer.component';
import { ChatFinalizarComponent } from './chat-finalizar/chat-finalizar.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DetalhesContatoChatComponent } from './detalhes-contato-chat/detalhes-contato-chat.component';
import { ModalInfosClienteChamadoComponent } from './modal-infos-cliente-chamado/modal-infos-cliente-chamado.component';
import { ModalNovaMidiaRemvovivelClienteComponent } from './modal-nova-midia-remvovivel-cliente/modal-nova-midia-remvovivel-cliente.component';
import { ModalEditarMidiaRemvovivelClienteComponent } from './modal-editar-midia-remvovivel-cliente/modal-editar-midia-remvovivel-cliente.component';
import { ModalNovaMidiaRemvovivelDispositivoComponent } from './modal-nova-midia-remvovivel-dispositivo/modal-nova-midia-remvovivel-dispositivo.component';
import { ModalEditarMidiaRemvovivelDispositivoComponent } from './modal-editar-midia-remvovivel-dispositivo/modal-editar-midia-remvovivel-dispositivo.component';
import { ModalNovaLicencaComponent } from './modal-nova-licenca/modal-nova-licenca.component';
import { ModalVinculoLicencaComponent } from './modal-vinculo-licenca/modal-vinculo-licenca.component';
import { ModalVinculoLicencaEditarComponent } from './modal-vinculo-licenca-editar/modal-vinculo-licenca-editar.component';
import { ModalEditarLicencaComponent } from './modal-editar-licenca/modal-editar-licenca.component';
import { ModalCriarCampoPersonalizavelComponent } from './modal-criar-campo-personalizavel/modal-criar-campo-personalizavel.component';
import { LgpdAvisoComponent } from './lgpd-aviso/lgpd-aviso.component';
import { LgpdComponent } from './lgpd/lgpd.component';
import { LgpdWidgetConfComponent } from './lgpd-widget-conf/lgpd-widget-conf.component';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { ModalExportarDispositivos } from './modal-exportar-dispositivos/modal-exportar-dispositivos.component';
import { SubcategoriaPrioridadeComponent } from './subcategoria-prioridade/subcategoria-prioridade.component';
import { ModalNovaConfiguracaoSlaComponent } from './modal-nova-configuracao-sla/modal-nova-configuracao-sla.component';
import { ModalEditarConfiguracaoSlaComponent } from './modal-editar-configuracao-sla/modal-editar-configuracao-sla.component';
import { ModalEditarSlaPadraoPrioridadeComponent } from './modal-editar-sla-padrao-prioridade/modal-editar-sla-padrao-prioridade.component';
import { ModalNovoCategoriaConfiguracaoSlaComponent } from './modal-novo-categoria-configuracao-sla/modal-novo-categoria-configuracao-sla.component';
import { ModalEditarCategoriaConfiguracaoSlaComponent } from './modal-editar-categoria-configuracao-sla/modal-editar-categoria-configuracao-sla.component';
import { ModalNovoCategoriaClienteConfiguracaoSlaComponent } from './modal-novo-categoria-cliente-configuracao-sla/modal-novo-categoria-cliente-configuracao-sla.component';
import { ModalEditarCategoriaClienteConfiguracaoSlaComponent } from './modal-editar-categoria-cliente-configuracao-sla/modal-editar-categoria-cliente-configuracao-sla.component';
import { ModalEditarPadraoPrioridadeComponent } from './modal-editar-padrao-prioridade/modal-editar-padrao-prioridade.component';
import { ModalEditarCategoriaClientePrioridadeSlaConfiguracaoComponent } from './modal-editar-categoria-cliente-prioridade-sla-configuracao/modal-editar-categoria-cliente-prioridade-sla-configuracao.component';
import { ModalNovoCategoriaClientePrioridadeSlaConfiguracaoComponent } from './modal-novo-categoria-cliente-prioridade-sla-configuracao/modal-novo-categoria-cliente-prioridade-sla-configuracao.component';
import { ModalNovoCategoriaPrioridadeConfiguracaoSlaComponent } from './modal-novo-categoria-prioridade-configuracao-sla/modal-novo-categoria-prioridade-configuracao-sla.component';
import { ModalEditarCategoriaPrioridadeConfiguracaoSlaComponent } from './modal-editar-categoria-prioridade-configuracao-sla/modal-editar-categoria-prioridade-configuracao-sla.component';
import { ModalCobrancaHoraGeralComponent } from './modal-cobranca-hora-geral/modal-cobranca-hora-geral.component';
import { ModalCobrancaTicketGeralComponent } from './modal-cobranca-ticket-geral/modal-cobranca-ticket-geral.component';
import { ModalCobrancaOutrosGeralComponent } from './modal-cobranca-outros-geral/modal-cobranca-outros-geral.component';
import { ModalCobrancaDispositivosComponent } from './modal-cobranca-dispositivos/modal-cobranca-dispositivos.component';
import { DetalhesDispositivoArmazenamentoComponent } from './detalhes-dispositivo-armazenamento/detalhes-dispositivo-armazenamento.component';
import { ModalDocumentosDispositivoComponent } from './modal-documentos-dispositivo/modal-documentos-dispositivo.component';
import { ModalPerfilDispositivoComponent } from './modal-perfil-dispositivo/modal-perfil-dispositivo.component';
import { ModalTransferenciaTicketComponent } from './modal-transferencia-ticket/modal-transferencia-ticket.component';
import { ModalChatConciliarComponent } from './modal-chat-conciliar/modal-chat-conciliar.component';
import { ModalChatContatoComponent } from './modal-chat-contato/modal-chat-contato.component';
import { CobrancasModalExibirLogComponent } from './cobrancas-modal-exibir-log/cobrancas-modal-exibir-log.component';
import { ModalNovoEmailDominioComponent } from './modal-novo-email-dominio/modal-novo-email-dominio.component';
import { ModalEditarEmailDominioComponent } from './modal-editar-email-dominio/modal-editar-email-dominio.component';
import { ModalNovoContatoComponent } from './modal-novo-contato/modal-novo-contato.component';
import { ModalEditarContatoComponent } from './modal-editar-contato/modal-editar-contato.component';
import { ModalDispositivoConfigComponent } from './modal-dispositivo-config/modal-dispositivo-config.component';
import { CobrancasModalHistoricoComponent } from './cobrancas-modal-historico/cobrancas-modal-historico.component';
import { NovaPastaBibliotecaModalComponent } from './nova-pasta-biblioteca-modal/nova-pasta-biblioteca-modal.component';
import { ModalTrialComponent } from './modal-trial/modal-trial.component';
import { CaixaEmailInformacaoComponent } from './caixa-email-informacao/caixa-email-informacao.component';
import { ModalTarefaHorasComponent } from './modal-tarefa-horas/modal-tarefa-horas.component';
import { ClientDownloadTutorialComponent } from './client-download-tutorial/client-download-tutorial.component';
import { ClientDownloadInformacaoComponent } from './client-download-informacao/client-download-informacao.component';
import { HourpickerPadraoModule } from '../hourpicker-padrao/hourpicker-padrao.module';
import { ModalFinalizarTarefaComponent } from './modal-finalizar-tarefa/modal-finalizar-tarefa.component';
import { MotivoPausaComponent } from './motivo-pausa/motivo-pausa.component';
import { ConversaZapComponent } from './conversa-zap/conversa-zap.component';
import { ModalIniciarConversaComponent } from './modal-iniciar-conversa/modal-iniciar-conversa.component';
import { ModalReagendamentoComponent } from './modal-reagendamento/modal-reagendamento.component';
import { EsqueceuSenhaComponent } from './esqueceu-senha/esqueceu-senha.component';
import { ConviteDownloadComponent } from './convite-download/convite-download.component';
import { ConexaoSshComponent } from './conexao-ssh/conexao-ssh.component';
import { CondicaoFiltroAvancadoComponent } from './condicao-filtro-avancado/condicao-filtro-avancado.component';
import { ModalConfiguracaoTrapComponent } from './modal-configuracao-trap/modal-configuracao-trap.component';
import { ModalEditarCampoFormularioComponent } from './modal-editar-campo-formulario/modal-editar-campo-formulario.component';
import { ModalVisualizarIframeComponent } from './modal-visualizar-iframe/modal-visualizar-iframe.component';
import { ConfiguracaoTrapComponent } from './configuracao-trap/configuracao-trap.component';
import { ModalNovoRelatorioComponent } from './modal-novo-relatorio/modal-novo-relatorio.component';
import { ModalFiltroRelatorioPersonalizadoComponent } from './modal-filtro-relatorio-personalizado/modal-filtro-relatorio-personalizado.component';
import { ModalColunaRelatorioPersonalizadoComponent } from './modal-coluna-relatorio-personalizado/modal-coluna-relatorio-personalizado.component';
import { ModalFiltroRelatorioPersonalizadoEditarComponent } from './modal-filtro-relatorio-personalizado-editar/modal-filtro-relatorio-personalizado-editar.component';
import { ModalListaDispositivoTopologiaComponent } from './modal-lista-dispositivo-topologia/modal-lista-dispositivo-topologia.component';
import { ModalListaConectorTopologiaComponent } from './modal-lista-conector-topologia/modal-lista-conector-topologia.component';
import { AutomacaoAlertasComponent } from './automacao-alertas/automacao-alertas.component';
import { AutomacaoAlertasCondicaoComponent } from './automacao-alertas-condicao/automacao-alertas-condicao.component';
import { AutomacaoAlertasAcaoComponent } from './automacao-alertas-acao/automacao-alertas-acao.component';
import { ModalVersaoClientDeviceComponent } from './modal-versao-client-device/modal-versao-client-device.component';
import { ModalSelecaoClienteTopologiaComponent } from './modal-selecao-cliente-topologia/modal-selecao-cliente-topologia.component';
import { AtualizarUsuariosEmMassaComponent } from './modal-atualizar-usuarios-em-massa/modal-atualizar-usuarios-em-massa.component';
import { ModalNovoClienteIntegracaoComponent } from './modal-novo-cliente-integracao/modal-novo-cliente-integracao.component';
import { IntegracaoExternaInformacaoComponent } from './integracao-externa-informacao/integracao-externa-informacao.component';
import { ModalConfiguracaoAlertaComponent } from './modal-configuracao-alerta/modal-configuracao-alerta.component';
import { ModalHoraFinalizarChatComponent } from './modal-hora-finalizar-chat/modal-hora-finalizar-chat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalExportarRelatorioCompletoComponent } from './modal-exportar-relatorio-completo/modal-exportar-relatorio-completo.component';
import { ModalExportarRelatorioSimplesComponent } from './modal-exportar-relatorio-simples/modal-exportar-relatorio-simples.component';
import { ModalTicketRetidoConciliarComponent } from './modal-ticket-retido-conciliar/modal-ticket-retido-conciliar.component';
import { ExportarRelatorioFaturamentoComponent } from './exportar-relatorio-faturamento/exportar-relatorio-faturamento.component';
import { ModalTicketRetidoBlacklistComponent } from './modal-ticket-retido-blacklist/modal-ticket-retido-blacklist.component';
import { ChatViewerAudioComponent } from './chat-viewer-audio/chat-viewer-audio.component';
import { ModalAvisoNotificacaoComponent } from './modal-aviso-notificacao/modal-aviso-notificacao.component';
import { ModalWorkflowCampoComponent } from './modal-workflow-campo/modal-workflow-campo.component';
import { DetalhesDispositivoPlacaVideoComponent } from './detalhes-dispositivo-placa-video/detalhes-dispositivo-placa-video.component';
import { ModalPausarSlaEditarTicketComponent } from './modal-pausar-sla-editar-ticket/modal-pausar-sla-editar-ticket.component';
import { ModalVulnerabilidadeDetalheComponent } from './modal-vulnerabilidade-detalhe/modal-vulnerabilidade-detalhe.component';
import { ModalChecklistAddChamadoComponent } from './modal-checklist-add-chamado/modal-checklist-add-chamado.component';
import { AutosizeModule } from 'ngx-autosize';
import { ModalNovaConfigSlaPadraoClienteComponent } from './modal-nova-config-sla-padrao-cliente/modal-nova-config-sla-padrao-cliente.component';
import { ModalEditarTipoDispositivoComponent } from './modal-editar-tipo-dispositivo/modal-editar-tipo-dispositivo.component';
import { LabelComponent } from '../label/label.component';
import { ModalConfigFiltrosComponent } from './modal-config-filtros/modal-config-filtros.component';
import { ModalEnviarFeedbackComponent } from './modal-config-filtros/modal-enviar-feedback/modal-enviar-feedback.component';
import { ModalAssinaturaEmailComponent } from './modal-assinatura-email/modal-assinatura-email.component';
import { ModalCampoPersonalizavelListaComponent } from './modal-campo-personalizavel-lista/modal-campo-personalizavel-lista.component';
import { ModalCampoPersonalizavelListaAtualizarOpcaoComponent } from './modal-campo-personalizavel-lista-atualizar-opcao/modal-campo-personalizavel-lista-atualizar-opcao.component';
import { AprovacaoModalComponent } from './aprovacao-modal/aprovacao-modal.component';
import { AprovarReprovarModalComponent } from './aprovar-reprovar-modal/aprovar-reprovar-modal.component';
import { ModalCampoCustomizavelDispositivoComponent } from './modal-campo-customizavel-dispositivo/modal-campo-customizavel-dispositivo.component';
import { ModalCalculoApontamentoHorasComponent } from './modal-calculo-apontamento-horas/modal-calculo-apontamento-horas.component';
import { ModalCrudProjetosComponent } from './modal-crud-projetos/modal-crud-projetos.component';
import { ModalNetworkScanComponent } from './modal-network-scan/modal-network-scan.component';
import { ModalDependenciasTicketProjetoComponent } from './modal-dependencias-ticket-projeto/modal-dependencias-ticket-projeto.component';
import { ModalEmailGpComponent } from './modal-email-gp/modal-email-gp.component';
import { TextEditorModule } from '../text-editor/text-editor.module';
import { ModalAutenticarLoginComponent } from './modal-autenticar-login/modal-autenticar-login.component';
import { ChatGptComponent } from '../chat-gpt/chat-gpt.component';
import { ButtonComponent } from '../button/button.component';
import { ModalCpuInfoDetalhesDispositivosComponent } from './modal-cpu-info-detalhes-dispositivos/modal-cpu-info-detalhes-dispositivos.component';
import { ModalGrupoSenhaComponent } from './modal-grupo-senha/modal-grupo-senha.component';
import { ModalNewCategoryComponent } from './modal-new-category/modal-new-category.component';
import { ModalEditCategoryGroupComponent } from './modal-edit-category-group/modal-edit-category-group.component';
import { ModalNewCategoryGroupComponent } from './modal-new-category-group/modal-new-category-group.component';
import { CategoriesMultiLevelModule } from '../categories-multi-level/categories-multi-level.module';
import { ModalRelateGroupCustomerComponent } from './modal-relate-group-customer/modal-relate-group-customer.component';
import { ModalNovoTokenIntegracaoComponent } from './modal-novo-token-integracao/modal-novo-token-integracao.component';
import { ModalAvisoDesativarDoisFatoresComponent } from './modal-aviso-desativar-dois-fatores/modal-aviso-desativar-dois-fatores.component';
import { ModalReenviarCodigoDoisFatoresComponent } from './modal-reenviar-codigo-dois-fatores/modal-reenviar-codigo-dois-fatores.component';
import { ModalAvisoAtivarDoisFatoresComponent } from './modal-aviso-ativar-dois-fatores/modal-aviso-ativar-dois-fatores.component';
import { ModalCodigoAutenticacaoDoisFatoresComponent } from './modal-codigo-autenticacao-dois-fatores/modal-codigo-autenticacao-dois-fatores.component';
import { IntegracaoGoogleAgendaComponent } from './integracao-google-agenda/integracao-google-agenda.component';
import { IframeGoogleScheduleComponent } from './iframe-google-schedule/iframe-google-schedule.component';
import { ModalCreateEditTvGroupComponent } from './modal-create-edit-tv-group/modal-create-edit-tv-group.component';
import { ModalTvModePublicLinkComponent } from './modal-tv-mode-public-link/modal-tv-mode-public-link.component';
import { NovoModalCampoPersonalizavelListaComponent } from './novo-modal-campo-personalizavel-lista/novo-modal-campo-personalizavel-lista.component';
import { NovoModalCriarCampoPersonalizavelComponent } from './novo-modal-criar-campo-personalizavel/novo-modal-criar-campo-personalizavel.component';
import { MesasDeTrabalhoExpedienteComponent } from './mesas-de-trabalho-expediente/mesas-de-trabalho-expediente.component';
import { ModalAddCamposCustomizaveisComponent } from './modal-add-campos-customizaveis/modal-add-campos-customizaveis.component';
import { ModalResetPasswordComponent } from './modal-reset-password/modal-reset-password.component';
import { ModalMsgStartChatWhatsappComponent } from './modal-msg-start-chat-whatsapp/modal-msg-start-chat-whatsapp.component';
import { ModalNovaUrlIntegracaoComponent } from './modal-nova-url-integracao/modal-nova-url-integracao.component';
import { ModalRegraCamposCustomizaveisComponent } from './modal-regra-campos-customizaveis/modal-regra-campos-customizaveis.component';
import { ModalRegraCamposCustomizaveisCondicaoComponent } from './modal-regra-campos-customizaveis-condicao/modal-regra-campos-customizaveis-condicao.component';
import { NoHtmlFormatterModule } from '../no-html-formatter/no-html-formatter.module';
import { ModalAcronisIntegrationComponent } from './modal-acronis-integration/modal-acronis-integration.component';
import { ModalConfigAlertsAcronisComponent } from './modal-config-alerts-acronis/modal-config-alerts-acronis.component';
import { ModalCrudAlertAcronisComponent } from './modal-crud-alert-acronis/modal-crud-alert-acronis.component';
import { ModalConfigCustomersAcronisComponent } from './modal-config-customers-acronis/modal-config-customers-acronis.component';
import { ModalLinkCustomerAcronisComponent } from './modal-link-customer-acronis/modal-link-customer-acronis.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalExpedientesComponent } from './modal-expedientes/modal-expedientes.component';
import { ModalWorkflowChatComponent } from './modal-workflow-chat/modal-workflow-chat.component';
import { ModalContatosComponent } from './modal-contatos/modal-contatos.component';
import { ModalIframeComponent } from './modal-iframe/modal-iframe.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    CriarPerfilUsuarioComponent,
    EditarPerfilUsuarioComponent,
    CriarTipoTarefaComponent,
    EditarTipoTarefaComponent,
    CriarTipoTicketComponent,
    EditarTipoTicketComponent,
    ModalCadastroClienteComponent,
    DetalhesArmazenaComponent,
    DetalhesRedeComponent,
    DetalhesDispositivoAlertasComponent,
    DetalhesDispositivoServicosComponent,
    DetalhesDispositivoSoftwaresComponent,
    SenhaVerificarUsuarioComponent,
    CriarTerceirizadaComponent,
    FeriadoComponent,
    ContatoComponent,
    ModalEnderecoClienteComponent,
    ModalAdicionarEquipeClienteComponent,
    ModalEditarAlertaClienteComponent,
    ModalUsuarioClienteComponent,
    ModalSetorClienteComponent,
    ModalCategoriaClienteComponent,
    ModalTicketProgramadoComponent,
    CategoriaComponent,
    SubcategoriaComponent,
    ModalBloquearClienteComponent,
    UsuarioComponent,
    MesasDeTrabalhoComponent,
    EquipeComponent,
    ModalImportarClientesComponent,
    ModalContratoComponent,
    ModalTipoCobrancaComponent,
    ModalCobrancaHoraComponent,
    ModalCobrancaTicketComponent,
    ModalCobrancaOutrosComponent,
    FluxoComponent,
    PassosComponent,
    ModalCadastroSenhaDispositivoComponent,
    ModalEditarSenhaDispositivoComponent,
    ModalEditarTipoDispositivoComponent,
    AtualizarEmMassaComponent,
    DetalhesChatHistoricoComponent,
    ChatContatoComponent,
    EditarVulnerabilidadeComponent,
    PerfilDispositivoComponent,
    PerfilDispositivoSoftwareComponent,
    BandaLargaComponent,
    ModalCriarDispositivoComponent,
    ModalAtualizacaoEditarComponent,
    ModalAtualizacaoSalvarComponent,
    ModalAcessoRemotoComponent,
    ModalAcessoRemotoSalvarComponent,
    DocumentosDiversosComponent,
    ContratosComponent,
    ArteGraficaComponent,
    ModalTicketLogsComponent,
    NovaConfiguracaoTicketComponent,
    EditarConfiguracaoAberturaComponent,
    MonitoramentoLinkComponent,
    MonitoramentoLinkPassosComponent,
    MonitoramentoLinkGraficoComponent,
    ModalTarefaComponent,
    CriarEscaneamentoComponent,
    EditarEscaneamentoComponent,
    EditarConfiguracaoAlertasComponent,
    ModalAberturaTicketComponent,
    AntivirusPadraoCriarModalComponent,
    AntivirusPersonalizadoCriarModalComponent,
    AntivirusPersonalizadoEditarModalComponent,
    AntivirusPadraoEditarModalComponent,
    ChecklistModalEditarComponent,
    ChecklistModalCriarComponent,
    ChecklistModalEditarCampoModalComponent,
    ModalPausarChamadoComponent,
    ModalCriarGrupoComponent,
    NovoGatilhoTicketModalComponent,
    NovoGatilhoSlaModalComponent,
    EditarGatilhoSlaModalComponent,
    EditarGatilhoTicketModalComponent,
    ModalComentarioTicketComponent,
    ModalOpenAITemplateComponent,
    ModalEnviarEmailComponent,
    ModalAssinaturaEmailComponent,
    ModalChecklistTicketComponent,
    ModalPecasChamadoComponent,
    ModalHorasTicketComponent,
    ModalApontarHorasTicketComponent,
    ModalOrdemServicoComponent,
    ModalConciliarTicketComponent,
    ModalFinalizarChamadoComponent,
    ModalReabrirTicketComponent,
    ModalAvaliacaoTicketComponent,
    MapaComponent,
    LicencaSoftwareVisualizarComponent,
    LicencaSoftwareEditarComponent,
    LicencaSoftwareEditarDispositivosVisualizarComponent,
    NovaConfigurcaoInadimplenciaComponent,
    EditarConfigurcaoInadimplenciaComponent,
    EmprestimoDispositivoComponent,
    LocarDispositivoComponent,
    EmprestimoDispositivoHistoricoComponent,
    CriarCobrancaPorHoraComponent,
    EditarCobrancaPorHoraComponent,
    CobrancasModalPdfPorEmailComponent,
    CobrancasModalPagarParcelaDescricaoComponent,
    CobrancasModalVisualizarContratoComponent,
    CobrancasModalNovaCobrancaComponent,
    ContratoLogsComponent,
    ModalEditarCatalogoServicoComponent,
    ModalNovoCatalogoServicoComponent,
    ModalListaContatosClienteComponent,
    ModalCriarContatoClienteComponent,
    CaixaEmailComponent,
    CaixaEmailHistoricoComponent,
    SeguirClienteComponent,
    ModalMapaOperadorComponent,
    ModalConfigPermissaoPerfilComponent,
    EditarArtigoBaseConhecimentoComponent,
    NovaPastaBaseConhecimentoComponent,
    NovoArtigoBaseConhecimentoComponent,
    MinhaFaturaPlanoComponent,
    ModalPendenciasFinalizarChamadoComponent,
    NovaChaveApiModalComponent,
    NovoScriptBibliotecaModalComponent,
    EditarScriptBibliotecaModalComponent,
    ModalEditarAssuntoTicketComponent,
    ModalAdicionarTemplateComponent,
    ModalTicketRetidoBloquearEmailComponent,
    ModalTicketRetidoLiberarEmailComponent,
    ModalTicketRetidoVisualizarChamadoComponent,
    ModalListaTarefasChamadoComponent,
    ModalDashboardAdicionarConfiguracaoDashboardComponent,
    WorkflowAutomacaoComponent,
    WorkflowCondicaoComponent,
    WorkflowAcaoComponent,
    ModalListaConferenciaTicketsComponent,
    ModalFiltroAvancadoComponent,
    ModalNovoFiltroAvancadoComponent,
    ModalEditarFiltroAvancadoComponent,
    ModalSalvarTemplateComponent,
    ModalMacrosTemplateComponent,
    ViewerTransferenciaComponent,
    ViewerComponent,
    ModalVisualizarTicketComponent,
    ModalNovaClassificacaoComponent,
    ModalEditarClassificacaoComponent,
    WidgetComponent,
    ModalExpedienteEmpresaComponent,
    FilaAtendimentoComponent,
    TabulacaoChatComponent,
    ModalWhiteLabelOnlineComponent,
    ModalWhiteLabelOfflineComponent,
    ModalConfigurarIntegracoesComponent,
    ModalConfigurarIntegracoesEspectraRamalComponent,
    ModalEditarIntegracoesEspectraRamalComponent,
    ModalEditarDadosGeraisComponent,
    ModalWorkflowDispositivoComponent,
    ModalWorkflowQuandoComponent,
    ModalWorkflowCondicoesComponent,
    ModalWorkflowScriptComponent,
    ModalWorkflowServicosComponent,
    ModalWorkflowAcaoComponent,
    ChatViewerComponent,
    ChatFinalizarComponent,
    DetalhesContatoChatComponent,
    ModalInfosClienteChamadoComponent,
    ModalNovaMidiaRemvovivelClienteComponent,
    ModalEditarMidiaRemvovivelClienteComponent,
    ModalNovaMidiaRemvovivelDispositivoComponent,
    ModalEditarMidiaRemvovivelDispositivoComponent,
    ModalNovaLicencaComponent,
    ModalVinculoLicencaComponent,
    ModalVinculoLicencaEditarComponent,
    ModalEditarLicencaComponent,
    ModalCriarCampoPersonalizavelComponent,
    LgpdAvisoComponent,
    LgpdComponent,
    LgpdWidgetConfComponent,
    ModalExportarDispositivos,
    SubcategoriaPrioridadeComponent,
    ModalNovaConfiguracaoSlaComponent,
    ModalEditarConfiguracaoSlaComponent,
    ModalEditarSlaPadraoPrioridadeComponent,
    ModalNovoCategoriaConfiguracaoSlaComponent,
    ModalEditarCategoriaConfiguracaoSlaComponent,
    ModalNovoCategoriaClienteConfiguracaoSlaComponent,
    ModalEditarCategoriaClienteConfiguracaoSlaComponent,
    ModalEditarPadraoPrioridadeComponent,
    ModalEditarCategoriaClientePrioridadeSlaConfiguracaoComponent,
    ModalNovoCategoriaClientePrioridadeSlaConfiguracaoComponent,
    ModalNovoCategoriaPrioridadeConfiguracaoSlaComponent,
    ModalEditarCategoriaPrioridadeConfiguracaoSlaComponent,
    ModalCobrancaHoraGeralComponent,
    ModalCobrancaTicketGeralComponent,
    ModalCobrancaOutrosGeralComponent,
    ModalCobrancaDispositivosComponent,
    DetalhesDispositivoArmazenamentoComponent,
    ModalDocumentosDispositivoComponent,
    ModalPerfilDispositivoComponent,
    ModalTransferenciaTicketComponent,
    ModalChatConciliarComponent,
    ModalChatContatoComponent,
    CobrancasModalExibirLogComponent,
    ModalNovoEmailDominioComponent,
    ModalEditarEmailDominioComponent,
    ModalNovoContatoComponent,
    ModalEditarContatoComponent,
    ModalDispositivoConfigComponent,
    CobrancasModalHistoricoComponent,
    NovaPastaBibliotecaModalComponent,
    ModalTrialComponent,
    CaixaEmailInformacaoComponent,
    ModalTarefaHorasComponent,
    ClientDownloadTutorialComponent,
    ClientDownloadInformacaoComponent,
    ModalFinalizarTarefaComponent,
    MotivoPausaComponent,
    ConversaZapComponent,
    ModalIniciarConversaComponent,
    ModalReagendamentoComponent,
    EsqueceuSenhaComponent,
    ConviteDownloadComponent,
    ConexaoSshComponent,
    CondicaoFiltroAvancadoComponent,
    ModalConfiguracaoTrapComponent,
    ModalEditarCampoFormularioComponent,
    ModalVisualizarIframeComponent,
    ConfiguracaoTrapComponent,
    ModalNovoRelatorioComponent,
    ModalFiltroRelatorioPersonalizadoComponent,
    ModalColunaRelatorioPersonalizadoComponent,
    ModalFiltroRelatorioPersonalizadoEditarComponent,
    ModalListaDispositivoTopologiaComponent,
    ModalListaConectorTopologiaComponent,
    AutomacaoAlertasComponent,
    AutomacaoAlertasCondicaoComponent,
    AutomacaoAlertasAcaoComponent,
    ModalVersaoClientDeviceComponent,
    ModalSelecaoClienteTopologiaComponent,
    AtualizarUsuariosEmMassaComponent,
    ModalNovoClienteIntegracaoComponent,
    IntegracaoExternaInformacaoComponent,
    ModalConfiguracaoAlertaComponent,
    ModalHoraFinalizarChatComponent,
    ModalExportarRelatorioCompletoComponent,
    ModalExportarRelatorioSimplesComponent,
    ModalTicketRetidoConciliarComponent,
    ExportarRelatorioFaturamentoComponent,
    ModalTicketRetidoBlacklistComponent,
    ChatViewerAudioComponent,
    ModalAvisoNotificacaoComponent,
    ModalWorkflowCampoComponent,
    DetalhesDispositivoPlacaVideoComponent,
    ModalPausarSlaEditarTicketComponent,
    ModalVulnerabilidadeDetalheComponent,
    ModalChecklistAddChamadoComponent,
    ModalNovaConfigSlaPadraoClienteComponent,
    InputComponent,
    LabelComponent,
    ModalConfigFiltrosComponent,
    ModalEnviarFeedbackComponent,
    ModalCampoPersonalizavelListaComponent,
    ModalCampoPersonalizavelListaAtualizarOpcaoComponent,
    AprovacaoModalComponent,
    AprovarReprovarModalComponent,
    ModalCampoCustomizavelDispositivoComponent,
    ModalCalculoApontamentoHorasComponent,
    ModalCrudProjetosComponent,
    ModalNetworkScanComponent,
    ModalDependenciasTicketProjetoComponent,
    ModalEmailGpComponent,
    ModalAutenticarLoginComponent,
    ChatGptComponent,
    ButtonComponent,
    ModalCpuInfoDetalhesDispositivosComponent,
    ModalGrupoSenhaComponent,
    ModalCpuInfoDetalhesDispositivosComponent,
    ModalNewCategoryComponent,
    ModalNewCategoryGroupComponent,
    ModalEditCategoryGroupComponent,
    ModalRelateGroupCustomerComponent,
    ModalNovoTokenIntegracaoComponent,
    ModalAvisoDesativarDoisFatoresComponent,
    ModalReenviarCodigoDoisFatoresComponent,
    ModalAvisoAtivarDoisFatoresComponent,
    ModalCodigoAutenticacaoDoisFatoresComponent,
    IntegracaoGoogleAgendaComponent,
    IframeGoogleScheduleComponent,
    ModalCreateEditTvGroupComponent,
    ModalTvModePublicLinkComponent,
    NovoModalCampoPersonalizavelListaComponent,
    NovoModalCriarCampoPersonalizavelComponent,
    MesasDeTrabalhoExpedienteComponent,
    ModalAddCamposCustomizaveisComponent,
    ModalResetPasswordComponent,
    ModalMsgStartChatWhatsappComponent,
    ModalNovaUrlIntegracaoComponent,
    ModalRegraCamposCustomizaveisComponent,
    ModalRegraCamposCustomizaveisCondicaoComponent,
    ModalAcronisIntegrationComponent,
    ModalConfigAlertsAcronisComponent,
    ModalCrudAlertAcronisComponent,
    ModalConfigCustomersAcronisComponent,
    ModalLinkCustomerAcronisComponent,
    ModalExpedientesComponent,
    ModalWorkflowChatComponent,
    ModalContatosComponent,
    ModalIframeComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    SelectsModule,
    NgxMaskModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    DirectivesModule,
    NgxCurrencyModule,
    TagInputModule,
    NgSelectModule,
    InputTagsPadraoModule,
    PipesModule,
    FiltrosModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    TableSelectModule,
    NgxSpinnerModule,
    MilvusTreePadraoModule,
    PaginationModule,
    CodemirrorModule,
    DatepickerPadraoModule,
    Ng2SearchPipeModule,
    PickerModule,
    ColorPickerModule,
    HourpickerPadraoModule,
    AutosizeModule,
    TextEditorModule,
    CategoriesMultiLevelModule,
    NoHtmlFormatterModule,
    CKEditorModule
  ],
  exports: [InputComponent, LabelComponent]
})
export class ModalModule { }