<div>
    <input type="text" [(ngModel)]="searchCategories" class="search-drop-cat-multi" placeholder="{{'PESQUISAR' | translate}}">
    <div ngbAccordion>
        <ng-template #recursiveList let-categories>
            <div ngbAccordionItem *ngFor="let category of categories | filter: searchCategories" [collapsed]="verifyExpandSecondaries(category)">
                <h2 ngbAccordionHeader>
                    <div *ngIf="category.sub.length > 0; else noSubCategory" class="d-flex">
                        <button ngbAccordionButton></button>
                        <span class="category-name" (click)="setCategoryAsValue(category)" [ngClass]="{'category-selected': category.selected, 'no-drop': selectOnlyLastSub && !category.last_layer}" container="body" [ngbTooltip]="(selectOnlyLastSub && !category.last_layer) ? ('SELECIONE_ULTIMA_SUBCATEGORIA' | translate) : ''">{{category.nome}}</span>
                    </div>
                    <ng-template #noSubCategory>
                        <span class="category-btn-not-child" (click)="setCategoryAsValue(category)" [ngClass]="{'category-selected': category.selected, 'last-category-layer': selectOnlyLastSub && category.last_layer}">{{category.nome}}</span>
                    </ng-template>
                </h2>
                <div ngbAccordionCollapse>
                    <div *ngIf="category.sub.length > 0" ngbAccordionBody>
                        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: category.sub }"></ng-container>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: categories }"></ng-container>
    </div>
</div>