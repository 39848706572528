import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
    params: {}
  };
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getListagemWidgets(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/widget/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createWidget(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/widget`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createWidgetConfig(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/widget/configuracao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateWidget(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/widget`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateWidgetConfig(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/widget/configuracao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getWidgetConfById(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/widget/configuracao/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteWidget(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/widget/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarScript(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/widget/gerar-script/${id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  verificaPermissao(type, widget_id?): Observable<any> {
    let link = ''
    if (!widget_id) {
      link = `${this.apiUrl}api/widget/verifica-utilizacao/${type}`
    } else {
      link = `${this.apiUrl}api/widget/verifica-utilizacao/${type}/${widget_id}`
    }
    this.httpOptions.params = '';
    return this.http.get(link, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* DROPDOWNS */

  getDropDownWidget(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/widget/dropdown/widgets`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTipoDistribuicao(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/widget/dropdown/tipoDistribuicao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}