import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { DoisFatoresGuard } from "../app/core/auth/dois-fatores.guard"

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboards",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "dashboards",
        loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule)
      },
      {
        path: "devices",
        loadChildren: () => import('./pages/dispositivos/dispositivos.module').then(m => m.DispositivosModule)
      },
      {
        path: "registration",
        loadChildren: () => import('./pages/cadastro/cadastro.module').then(m => m.CadastroModule)
      },
      {
        path: "tickets",
        loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: "chat",
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: "settings",
        loadChildren: () => import('./pages/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule)
      },
      {
        path: "remote-access",
        loadChildren: () => import('./pages/acesso-remoto/acesso-remoto.module').then(m => m.AcessoRemotoModule)
      }, {
        path: "revenues",
        loadChildren: () => import('./pages/faturamento/faturamento.module').then(m => m.FaturamentoModule)
      },
      {
        path:"library",
        loadChildren: () => import('./pages/biblioteca/biblioteca.module').then(m => m.BibliotecaModule)
      },
      {
        path: "user-area",
        loadChildren: () => import('./pages/area-do-usuario/area-do-usuario.module').then(m => m.AreaUsuarioModule)
      },
      {
        path: "company-area",
        loadChildren: () => import('./pages/dados-empresa/dados-empresa.module').then(m => m.DadosEmpresaModule)
      },
      {
        path: "reports",
        loadChildren: () => import('./pages/relatorio/relatorio.module').then(m => m.RelatorioModule)
      },
      {
        path: "privacy",
        loadChildren: () => import('./pages/privacidade/privacidade.module').then(m => m.PrivacidadeModule)
      },
      {
        path: "calendar",
        loadChildren: () => import('./pages/calendario/calendario.module').then(m => m.CalendarioModule)
      },
      {
        path: "not-permission",
        loadChildren: () => import('./shared/sem-permissao/sem-permissao.module').then(m => m.SemPermissaoModule)
      },
      {
        path: "global-search",
        loadChildren: () => import('./pages/busca-global/busca-global.module').then(m => m.BuscaGlobalModule)
      },
      {
        path: "trial",
        loadChildren: () => import('./pages/trial/trial.module').then(m => m.TrialModule)
      },
      {
        path: "projects",
        loadChildren: () => import('./pages/gestao-projetos/gestao-projetos.module').then(m => m.GestaoProjetosModule)
      }
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "password-recovery",
        loadChildren: () => import('./pages/recuperar-senha/recuperar-senha.module').then(m => m.RecuperarSenhaModule)
      },
      {
        path: "examples",
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      },
      {
        path: "login",
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
      },
      {
        path: "login/:username/:password/:loginAutomatico",
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
      },
      {
        path: "remote-access",
        loadChildren: () => import('./pages/acesso-remoto/acesso-remoto.module').then(m => m.AcessoRemotoModule)
      },
    ],
  },
  {
    path: "**",
    redirectTo: "dashboards",
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
    })
  ],
  exports: [RouterModule],
 // providers: [TestInterceptor]
})
export class AppRoutingModule { }
